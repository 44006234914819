import axios from "axios";

const baseApi = "/api/route?path=disbursement/ssn-details";

export const requestValidateUser = () =>
  axios.request({
    method: "get",
    url: baseApi + "/validate",
  });

export const requestCaptureSSNDetails = (data) =>
  axios.request({
    method: "post",
    url: baseApi + "/capture",
    data,
  });
