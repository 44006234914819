import { call, put, select, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import {
  requestCosignerDetails,
  requestSaveCosignerDetails,
} from "components/Form/CosignerDetailsForm/redux/CosignerDetailsApi";
import {
  fetchCosignerDetails,
  saveCosignerDetails,
  updateApplication,
  updateExperianErrorResponse,
  updateInitialValues,
  updateIsPageLoading,
  updateIsSaving,
} from "components/Form/CosignerDetailsForm/redux/CosignerDetailsSlice";
import { mutateInitialValues } from "components/Form/CosignerDetailsForm/redux/CosignerDetailsHelper";
import { experianFetchStatusCode } from "components/EducationLoan/constants";
import Router from "next/router";
import {
  getFinalUEResult,
  pageName,
  productType,
  triggerInschoolTrackForm,
} from "lib/utils/events_utils";
import { trackEvent } from "scripts/segment";
import { logEvent } from "components/Analytics/google-analytics";

const getStore = (store) => store?.cosignerDetails;

function* handleFetchCosignerDetails(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestCosignerDetails, action.payload);
    if (res.data) {
      const cosignerData = mutateInitialValues(res?.data?.application);
      yield put(updateInitialValues(cosignerData));
      if (
        cosignerData?.experianResponse?.code ===
        experianFetchStatusCode.DETAILS_MISMATCH
      ) {
        yield put(updateExperianErrorResponse(cosignerData?.experianResponse));
      } else {
        yield put(updateExperianErrorResponse(null));
      }
      yield put(updateApplication(res?.data?.application));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsPageLoading(false));
  }
}

function* handleSaveCosignerDetails(action) {
  try {
    yield put(updateIsSaving(true));
    const res = yield call(requestSaveCosignerDetails, action.payload);
    const { application } = yield select(getStore);
    yield call(triggerInschoolTrackForm, {
      values: action.payload,
      applicationId: application?.id,
      screen: application?.applicationState?.screen,
      nextStage: res?.success && res?.data?.nextStage,
      underwriting: res?.data?.underWritingStatus,
      cosignerAttempt: res?.data?.cosignerAttempt,
      loanAmountInUSD: res?.data?.loanAmountInUsd,
      loanInterest: res?.data?.loanInterest,
    });
    yield call(trackEvent, "UE", {
      product: productType.INSCHOOL,
      applicationId: application?.id,
      pageName: pageName.IS_COSIGNER_DETAILS,
      referrer: document?.referrer || "NA",
      finalUEResult: getFinalUEResult(
        res?.data?.underWritingStatus,
        res?.data?.cosignerAttempt
      ),
    });
    if (res?.success) {
      yield call(
        logEvent,
        "Page Completed",
        `${application?.applicationState?.screen} completed`,
        `${application?.slug}`
      );
      Router.push(
        `/applications/${
          action.payload.slug
        }/${res?.data?.nextStage?.toLowerCase()}`
      );
    } else {
      if (res?.data?.code) {
        yield put(updateExperianErrorResponse(res?.data));
      } else {
        toast.error(errorMessages.SOMETHING_WENT_WRONG);
      }
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsSaving(false));
  }
}

function* cosignerDetailsSaga() {
  yield takeLatest(fetchCosignerDetails, handleFetchCosignerDetails);
  yield takeLatest(saveCosignerDetails, handleSaveCosignerDetails);
}

export default cosignerDetailsSaga;
