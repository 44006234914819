import {
  isDocumentPasswordError,
  isValidRoutingNumber,
  isValidSSNNumber,
} from "lib/utils/validationUtils";
import { isFutureDate, isValidDate } from "./dateValidityCheck";
import { regexExpressions } from "./regexExpressions";

export const pincodeValidation = (value) => {
  if (!regexExpressions.pincode.test(value)) {
    return "Please enter a valid pincode";
  }
};

export const generalValidation = (value) => {
  if (!value) return "This field is required";
};

export const otherRelationshipValidation = (value) => {
  if (!value) return "Please specify a relationship";
};

export const phoneNumberLengthValidation = (value) => {
  if (!value) return "Please enter a phone number";
  if (value.length !== 10) {
    return "Please enter a 10 digit valid phone number";
  }
};

export const emailValidation = (value) => {
  if (!value) return "Please enter an email";
  // NOTE : https://medium.com/swlh/how-to-validate-an-email-address-in-javascript-78d33f87f5c6
  if (!regexExpressions.email.test(value)) {
    return "Please enter a valid email";
  }
};

export const panNumberValidation = (value) => {
  if (!value) return "Please enter PAN Number";
  if (!regexExpressions.panNumber.test(value)) {
    return "Please enter a valid PAN";
  }
};

export const consentValidation = (value) => {
  if (!value) return "Please accept to move forward";
};

export const dateOfBirthValidation = (value) => {
  if (!value) return "Please enter a Date";
  const [year, month, day] = value.split("-");
  if (!isValidDate(Number(year), Number(month), Number(day)))
    return "Please enter a valid date in the format dd/mm/yyyy";
  if (isFutureDate(Number(year), Number(month), Number(day)))
    return "Please enter a valid date";
};

export const pincodeValidationWithList = async (value) => {
  const invalidPincodeErr = "Please enter a valid pincode";
  if (!value || !regexExpressions.pincode.test(value)) {
    return invalidPincodeErr;
  }
  const axios = (await import(`axios`)).default;
  const { data } = await axios(`/api/validate-pincode?pincode=${value}`);
  if (!data.valid) return invalidPincodeErr;
  return false;
};

export const routingNumberValidation = (value) => {
  generalValidation(value);
  if (!isValidRoutingNumber(value)) {
    return "Invalid number.";
  }
};

export const ssnNumberValidation = (value) =>
  !isValidSSNNumber(value) && "Invalid SSN number.";

export const validateDocument = (isMandatory = true) => (documents) => {
  if (isMandatory && !documents) {
    return "This document is mandatory";
  }

  if (
    documents &&
    // validate for single as well as multiple document upload
    (isDocumentPasswordError(documents[0]) ||
      documents.some((doc) => isDocumentPasswordError(doc)))
  ) {
    return "Please enter the password for the document.";
  }
};

export const isValidName = (val) => regexExpressions.name.test(val);
