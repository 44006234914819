import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentModal: false,
  processingPayment: false,
  orderId: "",
  clientSecret: "",
  outstandingFeesData: null,
  isPaymentFailed: false,
  paymentFailureReason: {},
  application: null,
  isCreatingOrder: false,
  isViewPF: false,
  isPageLoading: true,
  nextStage: "",
};

export const fetchFeeElements = createAction(
  "pfPendingDetails/fetchFeeElements"
);
export const fetchOutstandingFees = createAction(
  "pfPendingDetails/fetchOutstandingFees"
);
export const createOrder = createAction("pfPendingDetails/createOrder");
export const changeStageToPFPaid = createAction(
  "pfPendingDetails/changeStageToPFPaid"
);

const pfPendingDetailsSlice = createSlice({
  name: "pfPendingDetails",
  initialState,
  reducers: {
    updatePaymentModal(state, action) {
      return { ...state, paymentModal: action.payload };
    },
    updateProcessingPayment(state, action) {
      return { ...state, processingPayment: action.payload };
    },
    updateOrderId(state, action) {
      return { ...state, orderId: action.payload };
    },
    updateClientSecret(state, action) {
      return { ...state, clientSecret: action.payload };
    },
    updateOutstandingFeesData(state, action) {
      return { ...state, outstandingFeesData: action.payload };
    },
    updateIsPaymentFailed(state, action) {
      return { ...state, isPaymentFailed: action.payload };
    },
    updatePaymentFailureReason(state, action) {
      return { ...state, paymentFailureReason: action.payload };
    },
    updateApplication(state, action) {
      return { ...state, application: action.payload };
    },
    updateIsCreatingOrder(state, action) {
      return { ...state, isCreatingOrder: action.payload };
    },
    updateIsViewPF(state, action) {
      return { ...state, isViewPF: action.payload };
    },
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
    updateNextStage(state, action) {
      return { ...state, nextStage: action.payload };
    },
  },
});

export const {
  updatePaymentModal,
  updateProcessingPayment,
  updateOrderId,
  updateClientSecret,
  updateOutstandingFeesData,
  updateIsPaymentFailed,
  updatePaymentFailureReason,
  updateApplication,
  updateIsCreatingOrder,
  updateIsViewPF,
  updateIsPageLoading,
  updateNextStage,
} = pfPendingDetailsSlice.actions;
export default pfPendingDetailsSlice.reducer;
