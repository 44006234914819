import axios from "axios";

export const requestCoursesForCountry = (country) =>
  axios.request({
    method: "get",
    url: `/api/route?path=course/v2/fetchCampus/${country}`,
  });

export const requestSaveDetails = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=in-school/application/course-details",
    data,
  });

export const requestEligibleCampus = (country) =>
  axios.request({
    method: "get",
    url: `/api/route?path=course/v2/fetchEligibleCampus/${country}`,
  });

export const requestCourses = (campusId) =>
  axios.request({
    method: "get",
    url: `/api/route?path=course/fetchCourse/${campusId}`,
  });

export const requestSeasons = (country) =>
  axios.request({
    method: "get",
    url: `/api/route?path=in-school/seasons/${country}`,
  });
