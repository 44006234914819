import {
  generalValidation,
  otherRelationshipValidation,
  phoneNumberLengthValidation,
} from "lib/utils/validations";
import { referenceRelationshipOptions } from "components/EducationLoan/SelectOptions";
import { MultiSelect } from "components/Inputs/MultiSelect";
import { Input } from "components/Inputs/TextField";

export const generateReferenceFields = (referenceType) => {
  return {
    referenceName: {
      name: `reference${referenceType}.name`,
      component: Input,
      placeholder: "Enter Name of Reference",
      label: "Name of Reference",
      validate: generalValidation,
    },
    referencePhone: {
      name: `reference${referenceType}.phone`,
      component: Input,
      placeholder: "Enter Phone Number",
      label: "Phone Number",
      type: "number",
      validate: phoneNumberLengthValidation,
    },
    referenceRelationship: {
      name: `reference${referenceType}.relationship`,
      component: MultiSelect,
      label: `Relationship with Reference ${referenceType}`,
      placeholder: "Select a relationship",
      options: referenceRelationshipOptions,
      validate: generalValidation,
    },
    referenceRelationshipInDetail: {
      name: `reference${referenceType}.relationshipInDetail`,
      component: Input,
      label: "Specify Others",
      validate: otherRelationshipValidation,
    },
  };
};
