import { requestUserIdentifierForSegments } from "./commonApi";

export const getUserIdentifier = async () => {
  try {
    const res = await requestUserIdentifierForSegments();
    if (res?.data?.success) {
      return res?.data?.data;
    } else {
      return;
    }
  } catch (error) {
    console.error(error);
  }
  return null;
};
