import { docKeyNames } from "constant/docKeyNames";

export const mutateValues = (values) => ({
  ...values,
  bankProof: {
    ...values?.bankAccountDetailsProof[0],
    keyName: docKeyNames.BANK_ACCOUNT_DETAILS_PROOF,
  },
});

export const bankUpdateScreens = {
  NOT_ELIGIBLE: "NOT_ELIGIBLE",
  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_FORM: "UPDATE_FORM",
  PENDING: "PENDING",
};

export const getCtaText = (shouldSubmit, isSaving) => {
  if (shouldSubmit) {
    return isSaving ? "Saving..." : "Submit";
  }
  return "Proceed";
};
