export const screensAllowedInCourseDetailsStage = {
  COLLEGE_DETAILS: "COLLEGE",
  COURSE_TYPE: "COURSE",
  UNSUPPORTED_UNIVERSITY: "CAMPUS_NOT_SUPPORTED",
  UNSUPPORTED_COURSE: "NON_STEM",
  COUNTRY_NOT_SUPPORTED: "COUNTRY_NOT_SUPPORTED",
  COURSE_REJECTED: "COURSE_REJECTED",
};
export const courseInfo = {
  country: {
    maincontent: "Currently we only support masters courses in USA.",
    subcontent:
      " Our business team is working on launching new geographies to serve you better.",
    message: " We will notify you when your course is supported",
  },
  course: {
    maincontent: "We have added your course to our waitlist",
    subcontent: "",
    message:
      "Our team will reach out to you when we start supporting this course",
  },
};
export const formatOptions = (item) => ({
  label: item.name,
  value: item.id,
});
export const formatSeasons = (item) => ({
  label: item
    .split("_")
    .join(" ")
    .toUpperCase(),
  value: item,
});
