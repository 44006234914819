import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  changeStageToPFPaid,
  changeStageToPFPending,
  createOrder,
  fetchFeeElements,
  fetchOutstandingFees,
  updateApplication,
  updateClientSecret,
  updateFeesData,
  updateIsCreatingOrder,
  updateIsPageLoading,
  updateNextStage,
  updateOrderId,
  updatePaymentModal,
} from "components/EducationLoan/SanctionGenerated/redux/SanctionGeneratedSlice";
import { requestOutstandingFees as requestOutstandingFeesInPFPendingStage } from "components/EducationLoan/PFPending/redux/PFPendingApi";
import {
  requestCreateOrder,
  requestFeeElements,
  requestOutstandingFees,
  requestStageChange,
} from "components/EducationLoan/SanctionGenerated/redux/SanctionGeneratedApi";
import { errorMessages } from "lib/utils/constants";

function* handleFetchFeeElements(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestFeeElements, action.payload);
    if (res.data) {
      yield put(updateApplication(res?.data?.application));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsPageLoading(false));
  }
}

function* handleFetchOutstandingFees(action) {
  try {
    const res = yield call(requestOutstandingFees, action.payload);
    if (res?.data?.success) {
      yield put(updateFeesData(res?.data?.data));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  }
}

function* handleCreateOrder(action) {
  try {
    yield put(updateIsCreatingOrder(true));
    const res = yield call(requestCreateOrder, action.payload);
    if (res?.data?.success) {
      yield put(updateOrderId(res?.data?.data.orderId));
      yield put(updateClientSecret(res?.data?.data?.clientSecret));
      yield put(updatePaymentModal(true));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(updateIsCreatingOrder(false));
  }
}

function* handleStageChangeToPFPending(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestStageChange, action.payload);
    if (res?.data?.success) {
      yield put(updateNextStage(res?.data?.data?.toLowerCase()));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
      yield put(updateIsPageLoading(false));
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
    yield put(updateIsPageLoading(false));
  }
}

function* handleStageChangeToPFPaid(action) {
  try {
    yield put(updateIsPageLoading(true));
    let stageChangeToPFPendingResponse,
      stageChangeToPFPaidResponse,
      feesResponse;
    stageChangeToPFPendingResponse = yield call(
      requestStageChange,
      action.payload
    );
    if (stageChangeToPFPendingResponse?.data?.success) {
      // Fetching the outstanding fees API in which the discount is applied when application is in PF_PENDING stage.
      feesResponse = yield call(
        requestOutstandingFeesInPFPendingStage,
        action.payload
      );
      if (feesResponse?.data?.success) {
        stageChangeToPFPaidResponse = yield call(
          requestStageChange,
          action.payload
        );
        if (stageChangeToPFPaidResponse?.data?.success) {
          yield put(
            updateNextStage(
              stageChangeToPFPaidResponse?.data?.data?.toLowerCase()
            )
          );
        }
      }
    }
    if (
      !stageChangeToPFPendingResponse?.data?.success ||
      !feesResponse?.data?.success ||
      !stageChangeToPFPaidResponse?.data?.success
    ) {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
      yield put(updateIsPageLoading(false));
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
    yield put(updateIsPageLoading(false));
  }
}

function* sanctionGeneratedDetailsSaga() {
  yield takeLatest(fetchFeeElements, handleFetchFeeElements);
  yield takeLatest(fetchOutstandingFees, handleFetchOutstandingFees);
  yield takeLatest(createOrder, handleCreateOrder);
  yield takeLatest(changeStageToPFPending, handleStageChangeToPFPending);
  yield takeLatest(changeStageToPFPaid, handleStageChangeToPFPaid);
}

export default sanctionGeneratedDetailsSaga;
