import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import {
  captureSSNDetails,
  ssnDetailsUpdateActions,
  validateUser,
} from "components/UpdateSSNDetails/redux/SSNDetailsUpdateSlice";
import {
  requestCaptureSSNDetails,
  requestValidateUser,
} from "components/UpdateSSNDetails/redux/SSNDetailsUpdateApi";
import {
  mutateValues,
  ssnUpdateScreens,
} from "components/UpdateSSNDetails/redux/SSNDetailsUpdateHelper";

function* handleCaptureSSNDetails(action) {
  try {
    yield put(ssnDetailsUpdateActions.updateIsSavingForm(true));
    const res = yield call(
      requestCaptureSSNDetails,
      mutateValues(action.payload)
    );
    if (res?.data?.success) {
      yield put(
        ssnDetailsUpdateActions.updateScreen(ssnUpdateScreens.UPDATE_SUCCESS)
      );
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(ssnDetailsUpdateActions.updateIsSavingForm(false));
  }
}

function* handleValidateUser() {
  try {
    yield put(ssnDetailsUpdateActions.updateIsPageLoading(true));
    const res = yield call(requestValidateUser);
    if (res?.data?.success) {
      yield put(ssnDetailsUpdateActions.updateApplicationSlug(res?.data?.data));
      yield put(
        ssnDetailsUpdateActions.updateScreen(ssnUpdateScreens.UPDATE_FORM)
      );
    } else {
      yield put(
        ssnDetailsUpdateActions.updateScreen(ssnUpdateScreens.NOT_ELIGIBLE)
      );
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(ssnDetailsUpdateActions.updateIsPageLoading(false));
  }
}

function* ssnDetailsUpdateSaga() {
  yield takeLatest(captureSSNDetails, handleCaptureSSNDetails);
  yield takeLatest(validateUser, handleValidateUser);
}

export default ssnDetailsUpdateSaga;
