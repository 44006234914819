import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  changeStageToPFPaid,
  createOrder,
  fetchFeeElements,
  fetchOutstandingFees,
  updateApplication,
  updateClientSecret,
  updateIsCreatingOrder,
  updateIsPageLoading,
  updateNextStage,
  updateOrderId,
  updateOutstandingFeesData,
  updatePaymentModal,
} from "components/EducationLoan/PFPending/redux/PFPendingSlice";
import {
  requestCreateOrder,
  requestOutstandingFees,
} from "components/EducationLoan/PFPending/redux/PFPendingApi";
import { errorMessages } from "lib/utils/constants";
import {
  requestFeeElements,
  requestStageChange,
} from "components/EducationLoan/SanctionGenerated/redux/SanctionGeneratedApi";

function* handleFetchFeeElements(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestFeeElements, action.payload);
    if (res.data) {
      yield put(updateApplication(res?.data?.application));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsPageLoading(false));
  }
}

function* handleFetchOutstandingFees(action) {
  try {
    const res = yield call(requestOutstandingFees, action.payload);
    if (res?.data?.success) {
      yield put(updateOutstandingFeesData(res?.data?.data));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  }
}

function* handleCreateOrder(action) {
  try {
    yield put(updateIsCreatingOrder(true));
    const res = yield call(requestCreateOrder, action.payload);
    if (res?.data?.success) {
      yield put(updateOrderId(res?.data?.data.orderId));
      yield put(updateClientSecret(res?.data?.data?.clientSecret));
      yield put(updatePaymentModal(true));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(updateIsCreatingOrder(false));
  }
}

function* handleStageChangeToPFPaid(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestStageChange, action.payload);
    if (res?.data?.success) {
      yield put(updateNextStage(res?.data?.data?.toLowerCase()));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
      yield put(updateIsPageLoading(false));
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
    yield put(updateIsPageLoading(false));
  }
}

function* pfPendingDetailsSaga() {
  yield takeLatest(fetchFeeElements, handleFetchFeeElements);
  yield takeLatest(fetchOutstandingFees, handleFetchOutstandingFees);
  yield takeLatest(createOrder, handleCreateOrder);
  yield takeLatest(changeStageToPFPaid, handleStageChangeToPFPaid);
}

export default pfPendingDetailsSaga;
