export const postSanctionIndicator = [
  {
    id: 0,
    name: "VISA/I20",
    images: {
      pending: "visa-i20-pending.svg",
      active: "visa-i20-active.svg",
      done: "visa-i20-done.svg",
    },
    nextArrow: true,
  },
  {
    id: 1,
    name: "Sign Agreement",
    images: {
      pending: "signature-pending.svg",
      active: "signature-active.svg",
      done: "signature-pending.svg",
    },
    nextArrow: true,
  },
  {
    id: 2,
    name: "Request Funds",
    images: {
      pending: "request-funds-pending.svg",
      active: "request-funds-pending.svg",
      done: "request-funds-pending.svg",
    },
    nextArrow: false,
  },
];

export const GET_PAYMENT_REASON = {
  authentication_required:
    "Please try again and authenticate your card when prompted during the transaction. If the card issuer returns this decline code on an authenticated transaction,you need to contact your card issuer for more information.",
  approve_with_id:
    "Attempt the payment again. If you still can’t process it,you need to contact their card issuer.",
  call_issuer: "Please contact the card issuer for more information.",
  card_not_supported:
    "Please contact the card issuer to make sure your card can be used to make this type of purchase.",
  card_velocity_exceeded:
    "Please contact the card issuer for more information.",
  currency_not_supported:
    "Please check with the card issuer whether the card can be used for the type of currency specified.",
  do_not_honor: "Please contact the card issuer for more information.",
  do_not_try_again: "Please contact the card issuer for more information.",
  duplicate_transaction:
    "Please Check to see if a recent payment already exists.",
  expired_card: "Please use another card.",
  fraudulent: "Please contact the card issuer for more information.",
  generic_decline: "Please contact the card issuer for more information.",
  incorrect_number: "Please try again using the correct card number.",
  incorrect_cvc: "Please try again using the correct CVC.",
  incorrect_pin: "Please try again using the correct PIN.",
  incorrect_zip: "Please try again using the correct billing postal code.",
  insufficient_funds: "Please try again using an alternative payment method.",
  invalid_account:
    "Please contact your card issuer to check that the card is working correctly.",
  invalid_amount:
    "if the amount appears to be correct,you need to check with your card issuer that you can make purchases of that amount.",
  invalid_cvc: "Please try again using the correct CVC.",
  invalid_expiry_month: "Please try again using the correct expiry date.",
  invalid_expiry_year: "Please try again using the correct expiry date.",
  invalid_number: "Please try again using the correct expiry date.",
  invalid_pin: "Please try again using the correct PIN.",
  issuer_not_available:
    "Attempt the payment again. If you still can’t process it, the you need to contact your card issuer.",
  lost_card: "Please contact the card issuer for more information.",
  merchant_blacklist: "Please contact the card issuer for more information.",
  new_account_information_available:
    "Please contact the card issuer for more information.",
  no_action_taken: "Please contact the card issuer for more information.",
  not_permitted: "Please contact the card issuer for more information.",
  offline_pin_required:
    "Please try again by inserting your card and entering a PIN.",
  online_or_offline_pin_required:
    "If the card reader supports Online PIN, prompt the customer for a PIN without creating a new transaction. If the card reader doesn’t support Online PIN,Please try again by inserting your card and entering a PIN.",
  pickup_card: "Please contact the card issuer for more information.",
  pin_try_exceeded: "Please use another card or method of payment.",
  processing_error:
    "The payment needs to be attempted again. If it still can’t be processed, try again later.",
  reenter_transaction:
    "The payment needs to be attempted again. If it still can’t be processed,Please contact the card issuer.",
  restricted_card: "Please contact the card issuer for more information.",
  revocation_of_all_authorizations:
    "Please contact the card issuer for more information.",
  revocation_of_authorization:
    "Please contact the card issuer for more information.",
  security_violation: "Please contact the card issuer for more information.",
  service_not_allowed: "Please contact the card issuer for more information.",
  stolen_card: "Please contact the card issuer for more information.",
  stop_payment_order: "Please contact the card issuer for more information.",
  testmode_decline: "A genuine card must be used to make a payment.",
  transaction_not_allowed:
    "Please contact the card issuer for more information.",
  try_again_later:
    "Please try the payment again. If subsequent payments are declined,Please contact your card issuer for more information.",
  withdrawal_count_limit_exceeded:
    "Please try to to use an alternative payment method.",
};

export const highestEducationQualificationTypes = {
  DIPLOMA: "DIPLOMA",
  UG: "UG",
  PG: "PG",
};

export const employmentStatusTypes = {
  SALARIED: "SALARIED",
  SELFEMPLOYED: "SELFEMPLOYED",
  PENSIONER: "PENSIONER",
};

export const relationshipTypes = {
  OTHER: "OTHER",
  FATHER: "FATHER",
  MOTHER: "MOTHER",
};

export const parentsTitles = {
  MR: "MR",
  LATE: "LATE",
  DIVORCED: "DIVORCED",
};

export const referenceTypes = {
  REFERENCE1: "reference1",
  REFERENCE2: "reference2",
};

export const cosignerResidenceTypes = {
  RENTED: "RENTED",
  OWNED: "OWNED",
};

export const experianFetchStatusCode = {
  DETAILS_MISMATCH: 10,
  INTERNAL_SERVER_ERROR: 11,
};

export const extraCosignerAcceptanceStatus = {
  REJECTED: "REJECTED",
  RETRY: "RETRY",
  ADD_MORE: "ADD_MORE",
};

export const addressFieldsGeneratorTypes = {
  APPLICANT_CURRENT_ADDRESS: "applicantCurrentAddress",
  APPLICANT_PERMANENT_ADDRESS: "applicantPermanentAddress",
  COSIGNER_CURRENT_ADDRESS: "cosignerCurrentAddress",
  COSIGNER_PERMANENT_ADDRESS: "cosignerPermanentAddress",
};

export const testMinMaxScores = {
  IELTS: { min: 1, max: 9 },
  TOEFL: { min: 1, max: 120 },
  PTE: { min: 10, max: 90 },
  GRE: { min: 130, max: 170 },
  GMAT: { min: 0, max: 60 },
};

export const englishTestTypes = {
  IELTS: "IELTS",
};
