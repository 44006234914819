const initialState = {
  loading: true,
};

const usApplicationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === "UPDATE_LOADING") {
    return {
      ...state,
      loading: payload,
    };
  } else {
    return { ...state };
  }
};

export default usApplicationReducer;
