import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSavingForm: false,
  screen: "",
  isPageLoading: false,
  applicationSlug: "",
};

export const captureSSNDetails = createAction(
  "ssnDetailsUpdate/captureSSNDetails"
);

export const validateUser = createAction("ssnDetailsUpdate/validateUser");

const ssnDetailsUpdate = createSlice({
  name: "ssnDetailsUpdate",
  initialState,
  reducers: {
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
    updateIsSavingForm(state, action) {
      return { ...state, isSavingForm: action.payload };
    },
    updateScreen(state, action) {
      return { ...state, screen: action.payload };
    },
    updateApplicationSlug(state, action) {
      return { ...state, applicationSlug: action.payload };
    },
    updateApplicationSlug(state, action) {
      return { ...state, applicationSlug: action.payload };
    },
  },
});

export const ssnDetailsUpdateActions = { ...ssnDetailsUpdate.actions };
export default ssnDetailsUpdate.reducer;
