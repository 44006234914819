import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  application: null,
  creditReport: null,
  showPopup: false,
  showMismatchScreen: false,
  customError: null,
  creditCardModel: false,
  nextStage: null,
};

export const fetchApplicationDetails = createAction(
  "personalDetails/personalInfo"
);

export const fetchCreditReportDocument = createAction(
  "personalDetails/creditInfo"
);

export const savePersonalInfoData = createAction(
  "personalDetails/savePersonalInfo"
);

export const saveExperianMismatchData = createAction(
  "personalDetails/saveExperianMismatchData"
);

const personalDetailsSlice = createSlice({
  name: "personalDetails",
  initialState,
  reducers: {
    updateApplicationData(state, action) {
      const application = {
        ...action?.payload?.application,
        agreementConsent: action?.payload?.agreementConsent,
        experianResponse: action?.payload?.experianResponse,
        experianStatus: action?.payload?.experianStatus,
        creditCardConsent: action?.payload?.creditCardConsent,
      };
      return { ...state, application: application };
    },
    updateCreditReport(state, action) {
      return { ...state, creditReport: action?.payload };
    },
    updateShowExperianErrorPopup(state, action) {
      return { ...state, showPopup: action.payload };
    },
    updateShowExperianMismatchScreen(state, action) {
      return { ...state, showMismatchScreen: action.payload };
    },
    updateExperianCustomError(state, action) {
      return { ...state, customError: action.payload };
    },
    updateShowCreditCardModel(state, action) {
      return { ...state, creditCardModel: action.payload };
    },
    updateNextStage(state, action) {
      return { ...state, nextStage: action.payload };
    },
    updateExperianResponse(state, action) {
      return {
        ...state,
        application: { ...state.application, experianResponse: action.payload },
      };
    },
  },
});

export const {
  updateApplicationData,
  updateCreditReport,
  updateExperianCustomError,
  updateNextStage,
  updateShowCreditCardModel,
  updateShowExperianErrorPopup,
  updateShowExperianMismatchScreen,
  updateExperianResponse,
} = personalDetailsSlice.actions;
export default personalDetailsSlice.reducer;
