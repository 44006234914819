import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import Router from "next/router";
import { requestSaveBureauDetails } from "components/Form/Mismatch/redux/BureauDetailsApi";
import {
  saveBureauMismatchDetails,
  updateIsSaving,
  updateShowPopupExperianError,
} from "components/Form/Mismatch/redux/BureauDetailsSlice";

function* handleSaveBureauDetails(action) {
  try {
    yield put(updateIsSaving(true));
    const res = yield call(requestSaveBureauDetails, action.payload);
    if (res.data.success) {
      Router.push(
        `/applications/${
          action.payload.slug
        }/${res?.data?.data?.nextStage?.toLowerCase()}`
      );
    } else {
      yield put(updateShowPopupExperianError(true));
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsSaving(false));
  }
}

function* bureauDetailsSaga() {
  yield takeLatest(saveBureauMismatchDetails, handleSaveBureauDetails);
}

export default bureauDetailsSaga;
