import isEqual from "lodash/isEqual";
import ApplicantAddressSection from "components/Form/AddressDetails/ApplicantAddressSection";
import ApplicationStatusSection from "components/Form/AddressDetails/ApplicationStatusSection";
import CosignerAddressSection from "components/Form/AddressDetails/CosignerAddressSection";
import ReferencesSection from "components/Form/AddressDetails/ReferencesSection";

export const mutateAddressDetailsInitialValues = (application = {}) => {
  const {
    cosignerAttempt,
    cosigner1,
    cosigner2,
    reference1,
    reference2,
    applicationStatus,
    profile = {},
  } = application;

  const {
    firstName: profileFirstName,
    currentAddress: profileCurrentAddress,
    permanentAddress: profilePermanentAddress,
  } = profile;

  const cosigner = cosignerAttempt === 2 ? cosigner2 : cosigner1;

  const {
    firstName: cosignerFirstName,
    currentAddress: cosignerCurrentAddress,
    permanentAddress: cosignerPermanentAddress,
  } = cosigner || {};

  const isApplicantAddressSame = isEqual(
    profilePermanentAddress,
    profileCurrentAddress
  );
  const isCosignerAddressSame = isEqual(
    cosignerPermanentAddress,
    cosignerCurrentAddress
  );

  return {
    profile: { firstName: profileFirstName },
    reference1,
    reference2,
    applicationStatus,
    currentAddress: profileCurrentAddress,
    permanentAddress: profilePermanentAddress,
    cosigner: { firstName: cosignerFirstName },
    isApplicantAddressSame,
    isCosignerAddressSame,
    cosignerPermanentAddress,
    cosignerCurrentAddress,
  };
};

export const addressFieldsAccordionGenerator = (hasCosigner, values) =>
  [
    {
      title: "College Application Status",
      hasLoanBeenOffered: true,
      open: true,
      DisplayComponent: ApplicationStatusSection,
    },
    {
      title: "Applicant's Address",
      personalisedTitle: `${values?.profile?.firstName}'s Address`,
      hasLoanBeenOffered: true,
      DisplayComponent: ApplicantAddressSection,
    },
    hasCosigner && {
      title: "Co-signer's Address",
      personalisedTitle: `${values?.cosigner?.firstName}'s Address`,
      hasLoanBeenOffered: true,
      DisplayComponent: CosignerAddressSection,
    },
    {
      title: "References",
      hasLoanBeenOffered: true,
      DisplayComponent: ReferencesSection,
    },
  ].filter((accordion) => !!accordion);

export const mutateAddressDetailsValues = (values) => {
  let mutatedValues = { ...values };

  if (values?.isApplicantAddressSame)
    mutatedValues.permanentAddress = values.currentAddress;

  if (values?.isCosignerAddressSame)
    mutatedValues.cosignerPermanentAddress = values.cosignerCurrentAddress;

  return mutatedValues;
};
