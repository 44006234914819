export const docKeyNames = {
  ACH_FORM: "achForm",
  FLYWIRE: "flywire",
  IT_RETURN1: "it_return1",
  IT_RETURN2: "it_return2",
  BANK_STATEMENT_COSIGNER: "bankStatementCoSigner",
  SALARY_SLIP: "salary_slip",
  GST_RETURN: "gst_return",
  PP_ORDER: "ppOrder",
  OTHER: "other",
  PAN: "pan",
  AADHAR_COSIGNER: "aadharCosigner",
  RESIDENTIAL_PROOF: "resdential_proof",
  PHOTOGRAPH: "photograph",
  AADHAR_APPLICANT: "aadharApplicant",
  PASSPORT_FRONT: "passport_front",
  PASSPORT_BACK: "passport_back",
  X_MARKSHEET: "xMarksheet",
  XII_MARKSHEET: "xiiMarksheet",
  UG_TRANSCRIPT: "UGTranscript",
  PG_TRANSCRIPT: "PGTranscript",
  IT_RETURN: "it_return",
  BANK_STATEMENT_APPLICANT: "bankStatementApplicant",
  VISA: "visa",
  I20_FORM: "I20_Form",
  COLLEGE_ADMIT_CARD: "collegeAdmitCard",
  CREDIT_REPORT_DOC: "creditReportDoc",
  BANK_ACCOUNT_DETAILS_PROOF: "bankAccountDetailsProof",
  SSN: "ssn",
};
