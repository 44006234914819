// To do: Add Redux persist so that store is not refreshed when page/component changes

const initialState = {
  applicantData: null,
  stage: "",
  slug: "",
  status: "",
  accountNumber: "",
  transferWireAccNum: "",
  loading: true,
  phone: "",
  transactions: {},
  bankAccountOpenedDetails: {},
};

const bankAccountReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ADD_APPLICANT_DATA":
      return {
        ...state,
        applicantData: payload,
      };
    case "UPDATE_STAGE":
      return {
        ...state,
        stage: payload,
      };
    case "ADD_SLUG":
      return {
        ...state,
        slug: payload,
      };
    case "SET_BANKACCOUNT_STATUS":
      return {
        ...state,
        status: payload,
      };
    case "UPDATE_LOADING":
      return {
        ...state,
        loading: payload,
      };
    case "ADD_PHONE":
      return {
        ...state,
        phone: payload,
      };
    case "ADD_ACCOUNT_NUMBER":
      return {
        ...state,
        accountNumber: payload,
      };
    case "ADD_TRANSFERWIRE_ACCOUNT":
      return {
        ...state,
        transferWireAccNum: payload,
      };
    case "ADD_TRANSACTIONS":
      return {
        ...state,
        transactions: {
          ...state.transactions,
          ...payload,
        },
      };
    case "ADD_BANKACCOUNT_OPENED_DETAILS":
      return {
        ...state,
        bankAccountOpenedDetails: { ...payload },
      };
    default:
      return { ...state };
  }
};

export default bankAccountReducer;
