import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPageLoading: false,
  isSavingForm: false,
  screen: "",
  applicationSlug: "",
};

export const captureBankDetails = createAction(
  "bankDetailsUpdate/captureBankDetails"
);

export const validateUser = createAction("bankDetailsUpdate/validateUser");

const bankDetailsUpdate = createSlice({
  name: "bankDetailsUpdate",
  initialState,
  reducers: {
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
    updateIsSavingForm(state, action) {
      return { ...state, isSavingForm: action.payload };
    },
    updateScreen(state, action) {
      return { ...state, screen: action.payload };
    },
    updateApplicationSlug(state, action) {
      return { ...state, applicationSlug: action.payload };
    },
  },
});

export const {
  updateIsPageLoading,
  updateIsSavingForm,
  updateScreen,
  updateApplicationSlug,
} = bankDetailsUpdate.actions;
export default bankDetailsUpdate.reducer;
