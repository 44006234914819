import apolloClient from "apolloClient";
import { gql } from "@apollo/client";
import ApplicationService from "services/applicationService";
import { inschoolStages } from "constant/inschoolStages";

export const requestProfessionalDetails = (slug) =>
  apolloClient.query({
    query: gql`
      query($slug: String!) {
        application(slug: $slug) {
          id
          slug
          hasScholarships
          scholarships {
            amount
          }
          profile {
            currentAnnualSalary
            testScores {
              test
              score
            }
            educations {
              college
              year
              gpa
              gpaBase
              backLogCount: backlogCount
              gapYearCount
              course
            }
            highestQualification
            aptitudeTest
            englishTest
            currentSavings
            totalExperience
            employments {
              employer
              designation
            }
          }
          duration
          course {
            courseId: id
            name
            campus {
              id
              name
              college {
                name
              }
            }
          }
          applicationCourseDetails {
            campus {
              id
              name
            }
          }
          durationInMonths: duration
          season
          applicationState {
            screen
            status
            underwriting
            subStage
          }
          offer {
            appScore
          }
        }
      }
    `,
    variables: { slug },
    fetchPolicy: "no-cache",
  });

export const requestSaveProfessionalDetails = (data) => {
  const app = new ApplicationService(data?.slug);
  return app.update(data, inschoolStages.PROFESSIONAL_DETAILS);
};
