export const mutateInitialValues = (values) => {
  const mutatedPhoneObj = {
    phoneNumber: values.phone,
    isVerified: values.isPhoneVerified,
  };
  delete values["isPhoneVerified"];
  return {
    ...values,
    pinCode: values.currentAddress.pincode,
    phone: mutatedPhoneObj,
  };
};
