export const isMaxTwoDecimals = (number) => {
  if (!isNaN(number)) {
    let decimalCount = 0;
    const strValue = number.toString();
    if (strValue.split(".")[1]) {
      decimalCount = strValue.split(".")[1].length;
    }
    if (decimalCount > 2) return false;
    else return true;
  }
};

export function isValidPANStructure(panNumber) {
  const regex = /([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  return regex.test(panNumber);
}

export function isValidEmailStructure(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const isValidRoutingNumber = (value) => value?.length === 9;

export const isValidSSNNumber = (value) =>
  !isNaN(value) &&
  value?.length === 9 &&
  !["111111111", "000000000"].includes(value);

export const isDocumentPasswordError = (doc) => doc && doc?.hasPasswordErr;
