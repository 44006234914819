import ReactGA from "react-ga";

export const initGA = () => {
  ReactGA.initialize(process.env.GA_TRACKING_CODE);
};

export const logPageView = (path) => {
  const page = path || window.location.pathname;
  ReactGA.pageview(page);
};

export const logEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
