import { Field } from "react-final-form";
import { highestEducationQualificationTypes } from "components/EducationLoan/constants";
import { highestQualificationOptions } from "components/EducationLoan/SelectOptions";
import { MultiSelect } from "components/Inputs/MultiSelect";
import { Input } from "components/Inputs/TextField";
import { Fieldset, Legend, Section } from "components/Form/sections";
import HighestEducationFieldsSkeleton from "components/Form/ProfessionalDetailsForm/HighestEducationFieldsSkeleton";
import { generateHighestQualificationFields } from "components/Form/ProfessionalDetailsForm/fieldsGenerator";

const EducationDetailsSection = ({ values }) => {
  const { UG, PG } = highestEducationQualificationTypes;
  return (
    <Section paddingTop>
      <Fieldset>
        <div className="mb-6">
          <Field
            name="highestQualification"
            component={MultiSelect}
            options={highestQualificationOptions}
            validate={(value) => {
              if (!value)
                return "Please select your highest level of education";
            }}
            label="Select highest level of education"
          />
        </div>
      </Fieldset>
      <Fieldset hasSeparator>
        <Legend>10th and 12th Scores</Legend>
        <div className="md:flex md:-mx-2">
          <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
            <Field
              name="class10Percentage"
              label="10th Percentage"
              placeholder="Enter 10th Percentage"
              component={Input}
              type="number"
              validate={(value) => {
                if (!value && value !== 0) return "This field is required";
                if (value < 0 || value > 100) {
                  return "Please enter a valid percentage";
                }
              }}
              helperText="If your score isn’t in percentage, please convert and enter."
            />
          </div>
          <div className="md:w-1/2 md:px-2">
            <Field
              name="class12Percentage"
              label="12th Percentage"
              placeholder="Enter 12th Percentage"
              component={Input}
              type="number"
              validate={(value) => {
                if (!value && value !== 0) return "This field is required";
                if (value < 0 || value > 100)
                  return "Please enter a valid percentage";
              }}
              helperText="If your score isn’t in percentage, please convert and enter."
            />
          </div>
        </div>
      </Fieldset>
      {[UG, PG].includes(values?.highestQualification) && (
        <HighestEducationFieldsSkeleton
          fields={generateHighestQualificationFields(UG)}
          title="Undergraduate Details"
          gpaLevel="UG"
          gpaLabel="GPA Base"
          hasSeparator={values?.highestQualification === PG}
        />
      )}
      {values?.highestQualification === PG && (
        <HighestEducationFieldsSkeleton
          fields={generateHighestQualificationFields(PG)}
          title="Postgraduate Details"
          gpaLevel="PG"
          gpaLabel="GPA Base"
        />
      )}
    </Section>
  );
};

export default EducationDetailsSection;
