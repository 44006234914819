import isArray from "lodash/isArray";

export const mutateDocuments = (applicant) => {
  applicant.docs = {};
  applicant?.documents.forEach((document) => {
    document.name = document.url.split("/")[document.url.split("/").length - 1];
    applicant.docs[document.documentName] = [];
    applicant.docs[document.documentName].push(document);
  });
  return applicant;
};

export const parseBankDocuments = (documentsArray) => {
  return documentsArray.reduce((a, c) => {
    if (isArray(a[c.documentName])) {
      // if it is a multiple doc upload
      a[c.documentName].push(c);
    } else {
      // single doc upload
      a[c.documentName] = [c];
    }
    return a;
  }, {});
};
