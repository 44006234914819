export const splitName = (fathersName, mothersName, fathersTitle) => {
  const fatherSplit = fathersName.split(" ");
  const motherSplit = mothersName.split(" ");
  let fatherFName,
    fatherMName,
    fatherLName,
    motherFName,
    motherMName,
    motherLName,
    firstName,
    middleName,
    lastName;
  if (fatherSplit.length === 3) {
    fatherFName = fatherSplit[0];
    fatherMName = fatherSplit[1];
    fatherLName = fatherSplit[2];
  } else {
    fatherFName = fatherSplit[0];
    fatherLName = fatherSplit[1];
  }
  if (motherSplit.length === 3) {
    motherFName = motherSplit[0];
    motherMName = motherSplit[1];
    motherLName = motherSplit[2];
  } else {
    motherFName = motherSplit[0];
    motherLName = motherSplit[1];
  }
  if (fathersTitle === "MR") {
    firstName = fatherFName;
    middleName = fatherMName;
    lastName = fatherLName;
  } else {
    firstName = motherFName;
    middleName = motherMName;
    lastName = motherLName;
  }
  return { firstName, middleName, lastName };
};
