const useStorage = (type) => {
  try {
    const isStorageAvailable = type && type.setItem;
    return {
      setItem: (key, value) => {
        try {
          if (isStorageAvailable) {
            type.setItem(key, value);
          }
        } catch (error) {
          console.log(error);
        }
      },
      getItem: (key) => {
        try {
          if (isStorageAvailable) {
            return type.getItem(key);
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      },
      removeItem: (key) => {
        try {
          if (isStorageAvailable) {
            type.removeItem(key);
          }
        } catch (error) {
          console.log(error);
        }
      },
    };
  } catch (error) {
    console.log(error);
    return {
      setItem: () => {},
      getItem: () => null,
      removeItem: () => {},
    };
  }
};

export const localStorageUtils = (() => {
  try {
    return typeof window !== "undefined"
      ? useStorage(window.localStorage)
      : null;
  } catch (error) {
    console.log(error);
    return null;
  }
})();

export const sessionStorageUtils = (() => {
  try {
    return typeof window !== "undefined"
      ? useStorage(window.sessionStorage)
      : null;
  } catch (error) {
    console.log(error);
    return null;
  }
})();
