import { ContactSectionVariants } from "components/shared/ContactDetailsSection/constants";

export const ContactDetailsCard = ({
  title,
  subText,
  name,
  email,
  phone,
  variant,
}) => {
  const bgColor =
    variant === ContactSectionVariants.PRIMARY
      ? "bg-grayish shadow-[0px_3px_4px_#00000029] border-[#F0EFF4]"
      : "bg-black-300";
  const titleColor =
    variant === ContactSectionVariants.PRIMARY
      ? "text-primary"
      : "text-blue-650";

  return (
    <div className={`border rounded-lg p-4 w-full ${bgColor}`}>
      <h2 className={`font-bold text-[18px] mb-1 ${titleColor}`}>{title}</h2>
      <p className="text-[#686868] mb-2 text-sm">{subText}</p>
      <div className="flex flex-col gap-1 text-sm text-[#1C1C1C]">
        {name && (
          <p>
            <span className="font-semibold">Name : </span>
            {name}
          </p>
        )}
        {email && (
          <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
            <span className="font-semibold">Email : </span>
            {email}
          </a>
        )}
        {phone && (
          <p>
            <span className="font-semibold">Phone Number : </span>
            {phone}
          </p>
        )}
      </div>
    </div>
  );
};

export default ContactDetailsCard;
