export const mutateInitialValues = (
  applicant,
  agreementAcceptedDate,
  experianStatus
) => {
  let creditCardConsent = applicant?.creditCardConsent;
  let agreementConsent;
  if (!applicant?.firstName || experianStatus === "SUCCESSFUL") {
    creditCardConsent = true;
    agreementConsent = true;
  }
  if (agreementAcceptedDate) {
    agreementConsent = true;
  }
  return { creditCardConsent, agreementConsent };
};
