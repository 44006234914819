import { call, put, select, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import {
  fetchPersonalDetails,
  savePersonalDetails,
  updateApplication,
  updateExperianErrorResponse,
  updateInitialValues,
  updateIsPageLoading,
  updateIsSaving,
} from "components/Form/PersonalDetailsForm/redux/PersonalDetailsSlice";
import {
  requestPersonalDetails,
  requestSavePersonalDetails,
} from "components/Form/PersonalDetailsForm/redux/PersonalDetailsApi";
import { mutateInitialValues } from "components/Form/PersonalDetailsForm/redux/PersonalDetailsHelper";
import Router from "next/router";
import { experianFetchStatusCode } from "components/EducationLoan/constants";
import { triggerInschoolTrackForm } from "lib/utils/events_utils";
import { logEvent } from "components/Analytics/google-analytics";

const getStore = (store) => store?.personalDetails;

function* handleFetchPersonalDetails(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestPersonalDetails, action.payload);
    if (res.data) {
      const { profile } = res?.data?.application || {};
      yield put(updateInitialValues(mutateInitialValues(profile)));
      if (
        profile?.experianResponse?.code ===
        experianFetchStatusCode.DETAILS_MISMATCH
      ) {
        yield put(updateExperianErrorResponse(profile?.experianResponse));
      } else {
        yield put(updateExperianErrorResponse(null));
      }
      yield put(updateApplication(res?.data?.application));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsPageLoading(false));
  }
}

function* handleSavePersonalDetails(action) {
  try {
    yield put(updateIsSaving(true));
    const res = yield call(requestSavePersonalDetails, action.payload);
    const { application } = yield select(getStore);
    yield call(triggerInschoolTrackForm, {
      values: action.payload,
      applicationId: application?.id,
      screen: application?.applicationState?.screen,
      nextStage: res?.success && res?.data,
    });
    if (res.success) {
      yield call(
        logEvent,
        "Page Completed",
        `${application?.applicationState?.screen} completed`,
        `${application?.slug}`
      );
      Router.push(
        `/applications/${action.payload.slug}/${res?.data?.toLowerCase()}`
      );
    } else {
      if (res?.data?.code) {
        yield put(updateExperianErrorResponse(res?.data));
      } else {
        toast.error(errorMessages.SOMETHING_WENT_WRONG);
      }
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsSaving(false));
  }
}

function* personalDetailsSaga() {
  yield takeLatest(fetchPersonalDetails, handleFetchPersonalDetails);
  yield takeLatest(savePersonalDetails, handleSavePersonalDetails);
}

export default personalDetailsSaga;
