import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialValues: {},
  application: null,
  isSaving: false,
  isPageLoading: false,
};

export const fetchCosignerDocumentsUpload = createAction(
  "cosignerDocumentsUpload/fetchCosignerDocumentsUpload"
);

export const saveCosignerDocumentsUpload = createAction(
  "cosignerDocumentsUpload/saveCosignerDocumentsUpload"
);

const cosignerDocumentsUploadSlice = createSlice({
  name: "cosignerDocumentsUpload",
  initialState,
  reducers: {
    updateInitialValues(state, action) {
      return { ...state, initialValues: action.payload };
    },
    updateApplication(state, action) {
      return { ...state, application: action.payload };
    },
    updateIsSaving(state, action) {
      return { ...state, isSaving: action.payload };
    },
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
  },
});

export const {
  updateInitialValues,
  updateApplication,
  updateIsSaving,
  updateIsPageLoading,
} = cosignerDocumentsUploadSlice.actions;
export default cosignerDocumentsUploadSlice.reducer;
