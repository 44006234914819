export const inschoolStages = {
  COURSE_DETAILS: "COURSE_DETAILS",
  COURSE_REJECTED: "COURSE_REJECTED",
  PERSONAL_DETAILS: "PERSONAL_DETAILS",
  PROFESSIONAL_DETAILS: "PROFESSIONAL_DETAILS",
  COSIGNER_DETAILS: "COSIGNER_DETAILS",
  OFFER_DETAILS: "OFFER_DETAILS",
  INACTIVITY_TIMEOUT: "INACTIVITY_TIMEOUT",
  PAYMENT_DETAILS: "PAYMENT_DETAILS",
  ADDRESS_DETAILS: "ADDRESS_DETAILS",
  DOCUMENTS_UPLOAD: "DOCUMENTS_UPLOAD",
  EXTRA_COSIGNER_DETAILS: "EXTRA_COSIGNER_DETAILS",
  COSIGNER_DOCUMENTS_UPLOAD: "COSIGNER_DOCUMENTS_UPLOAD",
  OFFER_CONFIRMATION: "OFFER_CONFIRMATION",
  SENT_TO_CREDIT: "SENT_TO_CREDIT",
  SANCTION_GENERATED: "SANCTION_GENERATED",
  EXTRA_CHARGE_COLLECTION: "EXTRA_CHARGE_COLLECTION",
  CREDIT_REJECTED: "CREDIT_REJECTED",
  HELD_BY_CREDIT: "HELD_BY_CREDIT",
  POST_SANCTION_DOC_PENDING: "POST_SANCTION_DOC_PENDING",
  FINAL_NEGOTIATED_OFFER: "FINAL_NEGOTIATED_OFFER",
  CONTRACT_PENDING: "CONTRACT_PENDING",
  PF_PENDING: "PF_PENDING",
  PF_PAID: "PF_PAID",
  POST_PF_NEGOTIATION: "POST_PF_NEGOTIATION",
  CONTRACT_REVIEW: "CONTRACT_REVIEW",
  CONTRACT_APPROVED: "CONTRACT_APPROVED",
  OK_TO_DISBURSE: "OK_TO_DISBURSE",
};

export const inschoolSubStages = {
  ADD_EXTRA_COSIGNER: "ADD_EXTRA_COSIGNER",
  ATTACH_EXTRA_DOCUMENT: "ATTACH_EXTRA_DOCUMENT",
};

export const underwritingStatus = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  COSIGNER_REJECTED: "COSIGNER_REJECTED",
  COSIGNER_NEEDED: "COSIGNER_NEEDED",
};

export const applicationStatusTypes = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const inschoolStagesPostOfferConfirmation = [
  inschoolStages.OFFER_CONFIRMATION,
  inschoolStages.SENT_TO_CREDIT,
  inschoolStages.SANCTION_GENERATED,
  inschoolStages.HELD_BY_CREDIT,
  inschoolStages.POST_SANCTION_DOC_PENDING,
  inschoolStages.CONTRACT_PENDING,
  inschoolStages.PF_PENDING,
  inschoolStages.PF_PAID,
  inschoolStages.POST_PF_NEGOTIATION,
  inschoolStages.CONTRACT_REVIEW,
  inschoolStages.CONTRACT_APPROVED,
  inschoolStages.OK_TO_DISBURSE,
];
export const stagesToShowBankBanner = [
  inschoolStages.SANCTION_GENERATED,
  inschoolStages.PF_PENDING,
  inschoolStages.PF_PAID,
  inschoolStages.EXTRA_CHARGE_COLLECTION,
  inschoolStages.POST_SANCTION_DOC_PENDING,
  inschoolStages.POST_PF_NEGOTIATION,
];

export const stageOrder = {
  [inschoolStages.COURSE_DETAILS]: 1,
  [inschoolStages.COURSE_REJECTED]: 1.1,
  [inschoolStages.PERSONAL_DETAILS]: 2,
  [inschoolStages.PROFESSIONAL_DETAILS]: 3,
  [inschoolStages.COSIGNER_DETAILS]: 4,
  [inschoolStages.OFFER_DETAILS]: 5,
  [inschoolStages.INACTIVITY_TIMEOUT]: 5.1,
  [inschoolStages.PAYMENT_DETAILS]: 6,
  [inschoolStages.ADDRESS_DETAILS]: 7,
  [inschoolStages.DOCUMENTS_UPLOAD]: 8,
  [inschoolStages.COSIGNER_DOCUMENTS_UPLOAD]: 9,
  [inschoolStages.EXTRA_COSIGNER_DETAILS]: 9,
  [inschoolStages.OFFER_CONFIRMATION]: 10,
  [inschoolStages.SENT_TO_CREDIT]: 11,
  [inschoolStages.CREDIT_REJECTED]: 12,
  [inschoolStages.HELD_BY_CREDIT]: 13,
  [inschoolStages.FINAL_NEGOTIATED_OFFER]: 15,
  [inschoolStages.EXTRA_CHARGE_COLLECTION]: 15.1,
  [inschoolStages.SANCTION_GENERATED]: 16,
  [inschoolStages.PF_PENDING]: 16.1,
  [inschoolStages.PF_PAID]: 16.2,
  [inschoolStages.POST_SANCTION_DOC_PENDING]: 17,
  [inschoolStages.POST_PF_NEGOTIATION]: 17.3,
  [inschoolStages.CONTRACT_PENDING]: 18,
  [inschoolStages.CONTRACT_REVIEW]: 22,
  [inschoolStages.CONTRACT_APPROVED]: 23,
  [inschoolStages.OK_TO_DISBURSE]: 26,
};
