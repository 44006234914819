import { call, put, select, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import {
  fetchDocumentsUpload,
  saveDocumentsUpload,
  updateApplication,
  updateInitialValues,
  updateIsPageLoading,
  updateIsSaving,
} from "components/Form/DocumentsUpload/redux/DocumentsUploadSlice";
import {
  requestSaveDocumentsUpload,
  requestUserDocumentsDetails,
} from "components/Form/DocumentsUpload/redux/DocumentsUploadApi";
import Router from "next/router";
import { triggerInschoolTrackForm } from "lib/utils/events_utils";
import { logEvent } from "components/Analytics/google-analytics";

const getStore = (store) => store?.documentsUpload;

function* handleFetchDocumentsUpload(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestUserDocumentsDetails, action.payload);
    if (res.data) {
      yield put(
        updateInitialValues(res?.data?.application?.profile?.documents)
      );
      yield put(updateApplication(res?.data?.application));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsPageLoading(false));
  }
}

function* handleSaveDocumentsUpload(action) {
  try {
    yield put(updateIsSaving(true));
    const res = yield call(requestSaveDocumentsUpload, action.payload);
    const { application } = yield select(getStore);
    yield call(triggerInschoolTrackForm, {
      values: action.payload,
      applicationId: application?.id,
      screen: application?.applicationState?.screen,
      nextStage: res?.success && res?.data,
    });
    if (res.success) {
      yield call(
        logEvent,
        "Page Completed",
        `${application?.applicationState?.screen} completed`,
        `${application?.slug}`
      );
      Router.push(
        `/applications/${action.payload.slug}/${res?.data?.toLowerCase()}`
      );
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsSaving(false));
  }
}

function* documentsUploadSaga() {
  yield takeLatest(fetchDocumentsUpload, handleFetchDocumentsUpload);
  yield takeLatest(saveDocumentsUpload, handleSaveDocumentsUpload);
}

export default documentsUploadSaga;
