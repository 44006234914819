import apolloClient from "../../../../apolloClient";
import { gql } from "@apollo/client";

export const requestApplicantData = (slug) => {
  return apolloClient.query({
    query: gql`
      query($slug: String) {
        bankApplication(slug: $slug) {
          id
          slug
          applicant {
            visaType
            visaUploaded
          }
        }
      }
    `,
    variables: { slug },
  });
};
