import { docKeyNames } from "constant/docKeyNames";

export const mutateValues = (values) => ({
  ...values,
  ssnDocument: {
    ...values?.ssn[0],
    keyName: docKeyNames.SSN,
  },
});

export const ssnUpdateScreens = {
  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_FORM: "UPDATE_FORM",
  NOT_ELIGIBLE: "NOT_ELIGIBLE",
};
