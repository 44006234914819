import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialValues: {},
  applicationState: {},
  isSaving: false,
  isPageLoading: false,
};

export const fetchAddressDetails = createAction(
  "addressDetails/fetchAddressDetails"
);

export const saveAddressDetails = createAction(
  "addressDetails/saveAddressDetails"
);

const addressDetailsSlice = createSlice({
  name: "addressDetails",
  initialState,
  reducers: {
    updateInitialValues(state, action) {
      return { ...state, initialValues: action.payload };
    },
    updateApplicationState(state, action) {
      return { ...state, applicationState: action.payload };
    },
    updateIsSaving(state, action) {
      return { ...state, isSaving: action.payload };
    },
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
  },
});

export const {
  updateInitialValues,
  updateApplicationState,
  updateIsSaving,
  updateIsPageLoading,
} = addressDetailsSlice.actions;
export default addressDetailsSlice.reducer;
