import { Field } from "react-final-form";

const AddressDetailsFieldsSkeleton = ({ fields }) => (
  <>
    <div className="md:mb-8 md:flex md:-mx-2">
      <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
        <Field {...fields?.pincodeField} />
      </div>
      <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
        <Field {...fields?.stateField} />
      </div>
    </div>
    <div className="mb-6 md:mb-8">
      <Field {...fields?.line1Field} />
    </div>
    <div className="md:flex md:-mx-2">
      <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
        <Field {...fields?.line2Field} />
      </div>
      <div className="md:w-1/2 md:px-2">
        <Field {...fields?.cityField} />
      </div>
    </div>
  </>
);

export default AddressDetailsFieldsSkeleton;
