import { HelpCenterContext } from "lib/helpCenter/HelpCenterContext";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const strapiApiUrl = process.env.KnowledgeBaseURL;

export function HelpCenterProvider({ children }) {
  const [helpCenterContent, setHelpCenterContent] = useState(null);
  const router = useRouter();

  const fetchHelpCenterContent = async () => {
    try {
      const helpCenterContentResponse = await fetch(
        `${strapiApiUrl}/faqs?slug=lf-student-info-faqs`
      );
      if (helpCenterContentResponse?.ok) {
        const helpCenterContent = await helpCenterContentResponse.json();
        setHelpCenterContent(helpCenterContent?.[0]?.faq);
      }
    } catch (error) {
      console.error("Error in fetching help center content from strapi", error);
    }
  };

  useEffect(() => {
    fetchHelpCenterContent();
  }, [router.pathname]);

  return (
    <HelpCenterContext.Provider value={helpCenterContent}>
      {children}
    </HelpCenterContext.Provider>
  );
}
