import axios from "axios";
import { localStorageUtils } from "lib/utils/storageUtils";
import { destroyCookie } from "nookies";
import { useCallback } from "react";
import { segmentReset } from "scripts/segment";

const useLogin = () => {
  // TODO: Add login, getUserInfo and other auth functionality in this hook.
  const logout = useCallback(async (redirectUrl = "/") => {
    try {
      localStorageUtils?.setItem("whatsappConsentAsked", "false");
      localStorageUtils?.removeItem("applicationSlug");
      segmentReset();
      destroyCookie(null, "userContext");
      await axios.post("/api/logout");
      if (typeof window !== "undefined") {
        window.open(redirectUrl, "_self");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }, []);
  return { logout };
};

export default useLogin;
