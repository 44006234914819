import { all } from "redux-saga/effects";
import pfPaidDetailsSaga from "components/EducationLoan/PFPaid/redux/PFPaidSaga";
import pfPendingDetailsSaga from "components/EducationLoan/PFPending/redux/PFPendingSaga";
import sanctionGeneratedDetailsSaga from "components/EducationLoan/SanctionGenerated/redux/SanctionGeneratedSaga";
import BASaga from "components/BankAccount/BASaga";
import DisbursementsSaga from "components/EducationLoan/Disbursements/redux/DisbursementsSaga";
import personalDetailsSaga from "components/Form/PersonalDetailsForm/redux/PersonalDetailsSaga";
import professionalDetailsSaga from "components/Form/ProfessionalDetailsForm/redux/ProfessionalDetailsSaga";
import cosignerDetailsSaga from "components/Form/CosignerDetailsForm/redux/CosignerDetailsSaga";
import offerDetailsSaga from "components/Form/LoanOfferScreen/redux/OfferDetailsSaga";
import monthlyPaymentDetailsSaga from "components/Form/MonthlyPaymentForm/redux/MonthlyPaymentSaga";
import addressDetailsSaga from "components/Form/AddressDetails/redux/AddressDetailsSaga";
import documentsUploadSaga from "components/Form/DocumentsUpload/redux/DocumentsUploadSaga";
import cosignerDocumentsUploadSaga from "components/Form/CosignerDocumentsUpload/redux/CosignerDocumentsUploadSaga";
import bureauDetailsSaga from "components/Form/Mismatch/redux/BureauDetailsSaga";
import bankDetailsUpdateSaga from "components/UpdateBankDetails/redux/BankDetailsUpdateSaga";
import applicationSaga from "components/Application/redux/ApplicationSaga";
import extraCosignerDetailsSaga from "components/Form/ExtraCosignerForm/redux/ExtraCosignerDetailsSaga";
import extraChargeCollectionSaga from "components/EducationLoan/ExtraChargeCollection/redux/ExtraChargeCollectionSaga";
import ssnDetailsUpdateSaga from "components/UpdateSSNDetails/redux/SSNDetailsUpdateSaga";
import referralDetailsSaga from "components/Referral/redux/ReferralSaga";

function* rootSaga() {
  yield all([
    BASaga(),
    DisbursementsSaga(),
    sanctionGeneratedDetailsSaga(),
    pfPendingDetailsSaga(),
    pfPaidDetailsSaga(),
    personalDetailsSaga(),
    professionalDetailsSaga(),
    cosignerDetailsSaga(),
    offerDetailsSaga(),
    monthlyPaymentDetailsSaga(),
    addressDetailsSaga(),
    documentsUploadSaga(),
    cosignerDocumentsUploadSaga(),
    bureauDetailsSaga(),
    bankDetailsUpdateSaga(),
    applicationSaga(),
    extraCosignerDetailsSaga(),
    extraChargeCollectionSaga(),
    ssnDetailsUpdateSaga(),
    referralDetailsSaga(),
  ]);
}

export default rootSaga;
