import { imageBasePath } from "lib/utils/imageUtils";
/**********  how it works page - start **********/

export const totalProcessingFee = process.env.TOTAL_PF;

export const processSteps = {
  application: [
    {
      title: "Online Application",
      content:
        "Complete application online - See your offer in 10 mins (no documents needed)",
      imgSrc: `${imageBasePath}/assets/images/online-application.svg`,
    },
    {
      title: "Claim Offer Online",
      content: "Accept the offer. Choose the amount and tenure of the loan.",
      imgSrc: `${imageBasePath}/assets/images/claim-offer.svg`,
    },
    {
      title: "Personal Discussion",
      content:
        "The credit team will have a call with you to know you better and answer any questions you may have.",
      imgSrc: `${imageBasePath}/assets/images/personal-discussion.svg`,
    },
  ],
  sanction: [
    {
      title: "Sanction Letter",
      content:
        "Download your official Sanction letter after paying a small processing fee of 1% of the loan amount. This letter can be used to get your i-20 and visa.",
      imgSrc: `${imageBasePath}/assets/images/download.svg`,
    },
    {
      title: "Upload I-20 & visa",
      content:
        "Once you get your I-20 and visa, upload them on your application profile page.",
      imgSrc: `${imageBasePath}/assets/images/upload.svg`,
    },
    {
      title: "Sign Loan Agreement",
      content:
        "Download the loan agreement. Both you and Leap Finance will sign the loan agreement.",
      imgSrc: `${imageBasePath}/assets/images/sign-contract.svg`,
    },
  ],
  disbursement: [
    {
      title: "Processing fee",
      content: `We charge a processing fee of ${totalProcessingFee}% when you request for funds.`,
      imgSrc: `${imageBasePath}/assets/images/credit-card.svg`,
    },
    {
      title: "Fund Transfer",
      content:
        "Your tuition fee will be transferred directly to the university through Flywire. Living expenses will be deposited into your US bank account.",
      imgSrc: `${imageBasePath}/assets/images/payment-method.svg`,
    },
    {
      title: "Repayments",
      content:
        "Pay partial monthly interest during the course which helps to build your credit score. Full EMI repayments on your loan start 6 months after you graduate.",
      imgSrc: `${imageBasePath}/assets/images/checkmark.svg`,
    },
  ],
};

/* faq data for different pages to be added here- start */
export const Faq = {
  loan: {
    title: "Education Loan FAQs for Abroad Studies",
    description:
      "Education Loan Faqs - Get answers to most frequently asked questions about Aborad Education Loans or contact us for more detail.",
    bannerImage: `${imageBasePath}/assets/images/index/faq-educationloan-banner.svg`,
    faqCategory: [
      {
        name: "Eligibility",
        icon: `${imageBasePath}/assets/images/index/faq-user-icon.svg`,
      },
      {
        name: "Process",
        icon: `${imageBasePath}/assets/images/index/icon-process.png`,
      },
      { name: "Terms", icon: `${imageBasePath}/assets/images/index/terms.svg` },
    ],
    introText: "Welcome!",
    paraText: <h1>How can we help you?</h1>,
    apiUrl: `${process.env.KnowledgeBaseURL}/faqs?slug_in=lf-inschool-faqs`,
  },
  bank: {
    title: "US Bank account for Abroad Studies",
    description:
      "Educ Faqs - Get answers to most frequently asked questions about Aborad Education Loans or contact us for more detail.",
    bannerImage: `${imageBasePath}/assets/images/index/faq-bankaccount-banner.png`,
    faqCategory: [
      {
        name: "Get started",
        icon: `${imageBasePath}/assets/images/index/faq-get-started-icon.svg`,
      },
      {
        name: "Transactions",
        icon: `${imageBasePath}/assets/images/index/faq-transfer-icon.svg`,
      },
      {
        name: "Account Info",
        icon: `${imageBasePath}/assets/images/index/faq-account-icon.svg`,
      },
      {
        name: "Security",
        icon: `${imageBasePath}/assets/images/index/faq-security-icon.svg`,
      },
    ],
    introText: <h1>US Bank Account Faqs</h1>,
    paraText: "How can we help you?",
    googleSheetUrl:
      "https://sheets.googleapis.com/v4/spreadsheets/1iQjT5U9ke2GUkqPd12IBSBLWQWfPNPUYONU36b-q_JU/values/bank-account?alt=json&key=AIzaSyDgeIm0LCCQ158k9OO30D9UirrHsbA-luc",
  },
};

/* common faq page data to be added here- start */

/* Google sheet data parse function - start */
export const parseResponse = (data, columnCount) => {
  var colCount = columnCount;
  var colMap = {};
  for (var i = 0; i < colCount; i++) {
    var obj = data.values[0][i];
    colMap[i + 1] = obj;
  }
  const values = data.values;
  values.shift();
  const array = values.map((value) => {
    let valueObject = {};
    for (const col in colMap) {
      valueObject[colMap[col]] = value[col - 1] ? value[col - 1] : "";
    }
    return valueObject;
  });
  return array;
};
/* Google sheet data parse function - end */

// Bank Account Stage Mapping

export const baStageMap = {
  BA_PERSONAL_INFO_CAPTURE: "personal_info",
  BA_DOCS_CAPTURE: "documents",
  BA_DISCLOSURE_PENDING: "disclosure",
  BA_VISA_INCOMPLETE: "visa_incomplete",
  BA_SENT_TO_SALES: "application_complete",
  BA_HELD_BY_SALES_DOCUMENT_AWAITED: "held_by_sales",
  BA_SENT_TO_PARTNER: "application_complete",
  BA_RETURN_BY_PARTNER: "application_complete",
  BA_ACCOUNT_OPENED: "application_complete",
  BA_SALES_REJECTED: "application_declined",
  BA_REJECTED_AFTER_PARTNER_RETURN: "application_declined",
};

// Indian States

export const indianStates = [
  {
    label: "Andhra Pradesh",
    value: "andhra_pradesh",
  },
  {
    label: "Arunachal Pradesh",
    value: "arunachal_pradesh",
  },
  {
    label: "Assam",
    value: "assam",
  },
  {
    label: "Bihar",
    value: "bihar",
  },
  {
    label: "Chhattisgarh",
    value: "chhattisgarh",
  },
  {
    label: "Goa",
    value: "goa",
  },
  {
    label: "Gujarat",
    value: "gujarat",
  },
  {
    label: "Haryana",
    value: "haryana",
  },
  {
    label: "Himachal Pradesh",
    value: "himachal_pradesh",
  },
  {
    label: "Jharkhand",
    value: "jharkhand",
  },
  {
    label: "Karnataka",
    value: "karnataka",
  },
  {
    label: "Kerala",
    value: "kerala",
  },
  {
    label: "Madhya Pradesh",
    value: "madhya_pradesh",
  },
  {
    label: "Maharashtra",
    value: "maharashtra",
  },
  {
    label: "Manipur",
    value: "manipur",
  },
  {
    label: "Meghalaya",
    value: "meghalaya",
  },
  {
    label: "Mizoram",
    value: "mizoram",
  },
  {
    label: "Nagaland",
    value: "nagaland",
  },
  {
    label: "Odisha",
    value: "odisha",
  },
  {
    label: "Punjab",
    value: "punjab",
  },
  {
    label: "Rajasthan",
    value: "rajasthan",
  },
  {
    label: "Sikkim",
    value: "sikkim",
  },
  {
    label: "Tamil Nadu",
    value: "tamil_nadu",
  },
  {
    label: "Telangana",
    value: "telangana",
  },
  {
    label: "Tripura",
    value: "tripura",
  },
  {
    label: "Uttarakhand",
    value: "uttarakhand",
  },
  {
    label: "Uttar Pradesh",
    value: "uttar_pradesh",
  },
  {
    label: "West Bengal",
    value: "west_bengal",
  },
  {
    label: "Andaman & Nicobar Islands",
    value: "andaman_and_nicobar_islands",
  },
  {
    label: "Chandigarh",
    value: "chandigarh",
  },
  {
    label: "Dadar and Nagar Haveli",
    value: "dadar_and_nagar_haveli",
  },
  {
    label: "Daman and Diu",
    value: "daman_and_diu",
  },
  {
    label: "Delhi",
    value: "delhi",
  },
  {
    label: "Jammu & Kashmir",
    value: "jammu_and_kashmir",
  },
  {
    label: "Ladakh",
    value: "ladakh",
  },
  {
    label: "Lakshadweep",
    value: "lakshadweep",
  },
  {
    label: "Punducherry",
    value: "punducherry",
  },
];

// US States

export const usStates = [
  {
    label: "Alabama",
    value: "alabama",
  },
  {
    label: "Alaska",
    value: "alaska",
  },
  {
    label: "Arizona",
    value: "arizona",
  },
  {
    label: "Arkansas",
    value: "arkansas",
  },
  {
    label: "California",
    value: "california",
  },
  {
    label: "Colorado",
    value: "colorado",
  },
  {
    label: "Connecticut",
    value: "connecticut",
  },
  {
    label: "Delaware",
    value: "delaware",
  },
  {
    label: "Florida",
    value: "florida",
  },
  {
    label: "Georgia",
    value: "georgia",
  },
  {
    label: "Hawaii",
    value: "hawaii",
  },
  {
    label: "Idaho",
    value: "idaho",
  },
  {
    label: "Illinois",
    value: "illinois",
  },
  {
    label: "Indiana",
    value: "indiana",
  },
  {
    label: "Iowa",
    value: "iowa",
  },
  {
    label: "Kansas",
    value: "kansas",
  },
  {
    label: "Kentucky",
    value: "kentucky",
  },
  {
    label: "Louisiana",
    value: "louisiana",
  },
  {
    label: "Maine",
    value: "maine",
  },
  {
    label: "Maryland",
    value: "maryland",
  },
  {
    label: "Massachusetts",
    value: "massachusetts",
  },
  {
    label: "Michigan",
    value: "michigan",
  },
  {
    label: "Minnesota",
    value: "minnesota",
  },
  {
    label: "Mississippi",
    value: "mississippi",
  },
  {
    label: "Missouri",
    value: "missouri",
  },
  {
    label: "Montana",
    value: "montana",
  },
  {
    label: "Nebraska",
    value: "nebraska",
  },
  {
    label: "Nevada",
    value: "nevada",
  },
  {
    label: "New Hampshire",
    value: "new_hampshire",
  },
  {
    label: "New Jersey",
    value: "new_jersey",
  },
  {
    label: "New Mexico",
    value: "new_mexico",
  },
  {
    label: "New York",
    value: "new_york",
  },
  {
    label: "North Carolina",
    value: "north_carolina",
  },
  {
    label: "North Dakota",
    value: "north_dakota",
  },
  {
    label: "Ohio",
    value: "ohio",
  },
  {
    label: "Oklahoma",
    value: "oklahoma",
  },
  {
    label: "Oregon",
    value: "oregon",
  },
  {
    label: "Pennsylvania",
    value: "pennsylvania",
  },
  {
    label: "Rhode Island",
    value: "rhode_island",
  },
  {
    label: "South Carolina",
    value: "south_carolina",
  },
  {
    label: "South Dakota",
    value: "south_dakota",
  },
  {
    label: "Tennessee",
    value: "tennessee",
  },
  {
    label: "Texas",
    value: "texas",
  },
  {
    label: "Utah",
    value: "utah",
  },
  {
    label: "Vermont",
    value: "vermont",
  },
  {
    label: "Virginia",
    value: "virginia",
  },
  {
    label: "Washington",
    value: "washington",
  },
  {
    label: "West Virginia",
    value: "west_virginia",
  },
  {
    label: "Wisconsin",
    value: "wisconsin",
  },
  {
    label: "Wyoming",
    value: "wyoming",
  },
];

export const defaultMmpValue = process.env.DEFAULT_MMP_VALUE;

// Bank Data

export const bankData = {
  header: (
    <p>
      Thinking about <span className="text-primary">coolest</span> way to carry
      dollars to US?
    </p>
  ),
  subHeader: (
    <p>
      Open your US Bank Account while in India | Get free Sable Credit Card |
      Transact from Day 0 in US
    </p>
  ),
  steps: [
    {
      image: "bank-step1.svg",
      title: "Step 1",
      subtitle: (
        <span>
          Fill your form online
          <br />
          Takes only 3 mins
        </span>
      ),
    },
    {
      image: "bank-step2.svg",
      title: "Step 2",
      subtitle: (
        <span>
          Upload documents &<br />
          Get your bank account
        </span>
      ),
    },
    {
      image: "bank-step3.svg",
      title: "Step 3",
      subtitle: (
        <span>
          Transfer money &<br />
          Start Transacting
        </span>
      ),
    },
  ],
};

export const productCardDetails = [
  {
    title: "USA Education Loan",
    subTitle: "Hassle-Free Process | Quick Sanction",
    imageUrl: `${imageBasePath}/assets/icons/education-loan-icon.svg`,
    href: "/loan-features/usa",
    webImageWidth: 60,
    webImageHeight: 60,
    mobileImageWidth: 32,
    mobileImageHeight: 32,
  },
];

export const nextStepsForUsa = [
  {
    title: "Pay processing fee",
    icon: `${imageBasePath}/assets/icons/credit-card.svg`,
  },
  {
    title: "Get US Bank Account",
    icon: `${imageBasePath}/assets/icons/bank.svg`,
  },
  {
    title: "Sign Loan Agreement",
    icon: `${imageBasePath}/assets/icons/sign-contract-blue.svg`,
  },
  {
    title: "Request funds",
    icon: `${imageBasePath}/assets/icons/funds.svg`,
  },
];

export const nextStepsForUsaIfNoUpfrontPF = nextStepsForUsa.filter(
  (val) => val.title !== "Pay processing fee"
);

export const stepsAfterPfFeesPaidForUSA = [
  {
    title: "Get US Bank Account",
    icon: `${imageBasePath}/assets/icons/bank.svg`,
  },
  {
    title: "Sign Loan Agreement",
    icon: `${imageBasePath}/assets/icons/sign-contract-blue.svg`,
  },
  {
    title: "Request funds",
    icon: `${imageBasePath}/assets/icons/funds.svg`,
  },
];

export const citizenships = {
  INDIAN: "INDIAN",
  US: "US",
};

export const leapOfficeAddress =
  "163/A, 9th Main Rd, Sector 6, HSR Layout, Bengaluru, Karnataka, 560102";

export const documentUploadInstructions = [
  "Capture clear, unfiltered, colour images of original documents with your camera/phone (scanned copy or photocopy is not allowed)",
  "All edges of the document must be clearly visible, not cut off.",
  "The image should not be overlaid with text/stickers.",
];

export const documentReUploadInstructions = [
  "All docs should be correct & clearly visible. No areas of the document should be blurry or cut off.",
  "Please upload the actual photograph of your original passport and i20. Scanned copy is NOT allowed.",
  "All docs should not be more than 500KB. If not, compress the file and upload.",
];

export const USVisaTypes = [
  {
    label: "F - 1 Visa",
    value: "F1",
  },
  {
    label: "C - 1/D Visa",
    value: "C1",
  },
  {
    label: "E - 1 Visa",
    value: "E1",
  },
  {
    label: "E - 2 Visa",
    value: "E2",
  },
  {
    label: "H - 1B Visa",
    value: "H1B",
  },
  {
    label: "I Visa",
    value: "I",
  },
  {
    label: "L - 1 Visa",
    value: "L1",
  },
  {
    label: "L - 1 Blanket Visa",
    value: "L1BLANKET",
  },
  {
    label: "O - 1 Visa",
    value: "O1",
  },
  {
    label: "TN Visa",
    value: "TN",
  },
  {
    label: "B - 1 Visa",
    value: "B1",
  },
  {
    label: "B - 2 Visa",
    value: "B2",
  },
  {
    label: "J - 1 Visa",
    value: "J1",
  },
  {
    label: "M - 1 Visa",
    value: "M1",
  },
  {
    label: "EB - 1 Visa",
    value: "EB1",
  },
  {
    label: "EB - 2 Visa",
    value: "EB2",
  },
  {
    label: "EB - 3 Visa",
    value: "EB3",
  },
  {
    label: "EB - 4 Visa",
    value: "EB4",
  },
  {
    label: "EB - 5 Visa",
    value: "EB5",
  },
];

export const BankAccountSupportedFormats = ["jpeg", "jpg", "png"];

export const BankAccountStages = {
  BA_PERSONAL_INFO_CAPTURE: "BA_PERSONAL_INFO_CAPTURE",
  BA_DOCS_CAPTURE: "BA_DOCS_CAPTURE",
  BA_HELD_BY_SALES_DOCUMENT_AWAITED: "BA_HELD_BY_SALES_DOCUMENT_AWAITED",
  BA_VISA_INCOMPLETE: "BA_VISA_INCOMPLETE",
};

export const BankAccountDocumentTypes = [
  { label: "Visa", value: "VISA" },
  {
    label: "Self-attested i20",
    value: "I20",
  },
];

export const ValidUSVisaTypes = {
  F1: "F1",
};

export const BankAccountDocumentStatus = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const ProductTypes = {
  INSCHOOL: "education",
  BANK: "bank",
};

export const errorMessages = {
  SOMETHING_WENT_WRONG: "Something went wrong. Please try after sometime",
  NOT_AUTHORIZED: "You are not authorized to view this page.",
  FAILED_TO_FETCH: "Failed to fetch data from server.",
  PLEASE_TRY_AGAIN: "Please try again after sometime.",
};

export const multiDocKeyNames = [
  "UGTranscript",
  "PGTranscript",
  "it_return",
  "salary_slip",
  "bankStatementApplicant",
  "bankStatementCoSigner",
  "salary_slip",
  "gst_return",
  "other",
];

export const ctaText = {
  GET_LOAN_OFFER: "Get Loan Offer",
  START_MY_APPLICATION: "Start my application",
};

export const MAKE_SURE_BEFORE_PROCEEDING =
  "These details can't be changed later. Please ensure they are correct before going to the next page.";

export const DOCUMENT_SUPPORTED_FORMATS_WITH_MAX_SIZE =
  "Supported Format: PNG, JPEG, JPG & PDF. Max Size: 20mb";

export const SECONDS_IN_12_HOURS = 43200;

export const DisbursementActivityState = {
  ACTIVATE: "ACTIVATE",
  DEACTIVATE: "DEACTIVATE",
};

export const DisbursementBlockingReasonMapper = {
  NON_FUNDED_UNIVERSITY: "non funded university",
  POOR_ACADEMICS: "poor academics",
  LOAN_CLOSED: "loan closure",
  DISCONTINUED_MASTERS: "discontinued Masters",
  FRAUDULENT_DOCUMENTS_SUBMITTED: "fraudulent documents submitted",
};
