import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialValues: {},
  application: null,
  experianErrorResponse: null,
  isSaving: false,
  isPageLoading: false,
};

export const fetchCosignerDetails = createAction(
  "cosignerDetails/fetchCosignerDetails"
);

export const saveCosignerDetails = createAction(
  "cosignerDetails/saveCosignerDetails"
);

const cosignerDetailsSlice = createSlice({
  name: "cosignerDetails",
  initialState,
  reducers: {
    updateInitialValues(state, action) {
      return { ...state, initialValues: action.payload };
    },
    updateApplication(state, action) {
      return { ...state, application: action.payload };
    },
    updateExperianErrorResponse(state, action) {
      return { ...state, experianErrorResponse: action.payload };
    },
    updateIsSaving(state, action) {
      return { ...state, isSaving: action.payload };
    },
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
  },
});

export const {
  updateInitialValues,
  updateApplication,
  updateExperianErrorResponse,
  updateIsSaving,
  updateIsPageLoading,
} = cosignerDetailsSlice.actions;
export default cosignerDetailsSlice.reducer;
