import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchApplicationDetails,
  updateApplicationData,
  fetchCreditReportDocument,
  updateCreditReport,
  savePersonalInfoData,
  updateExperianCustomError,
  updateNextStage,
  updateShowExperianErrorPopup,
  updateShowExperianMismatchScreen,
  saveExperianMismatchData,
  updateExperianResponse,
} from "./PersonalDetailsSlice";
import {
  requestPersonalInfo,
  requestApplicantCreditReport,
  savePersonalInfo,
  saveExperianMismatchInfo,
} from "./PersonalDetailsApi";
import { mutateInitialValues } from "./PersonalDetailsHelper";
import { toast } from "react-toastify";

function* handleFetchPersonalInfo(action) {
  try {
    const res = yield call(requestPersonalInfo, action.payload);
    const mutatedValues = mutateInitialValues(
      res?.data?.bankApplication?.applicant,
      res?.data?.bankApplication?.agreementAcceptedDate,
      res?.data?.bankApplication?.experianStatus
    );
    yield put(
      updateApplicationData({
        application: res?.data?.bankApplication?.applicant,
        experianStatus: res?.data?.bankApplication?.experianStatus,
        experianResponse: res?.data?.bankApplication?.experianResponse,
        creditCardConsent: mutatedValues?.creditCardConsent,
        agreementConsent: mutatedValues?.agreementConsent,
      })
    );
    if (
      res?.data?.bankApplication?.experianStatus === "FAILED" &&
      res?.data?.bankApplication?.experianResponse?.code === 10
    ) {
      yield put(updateShowExperianMismatchScreen(true));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleCreditReportFetch(action) {
  try {
    const res = yield call(requestApplicantCreditReport, action.payload);
    const documents = res?.data?.bankApplication?.applicant?.documents.filter(
      (doc) => {
        return (
          doc.documentName !== "visa" && doc.documentName !== "passport_front"
        );
      }
    );
    yield put(
      updateCreditReport({
        creditReport: documents,
      })
    );
  } catch (err) {
    console.error(err);
  }
}

function* handleSavePersonalInfoData(action) {
  try {
    const res = yield call(savePersonalInfo, action.payload);
    if (!res?.data?.success) {
      console.error(res?.data?.message);
      toast.error(
        res?.data?.message ||
          "Unable to process your details at the moment. Please try again later."
      );
      return;
    }
    if (res?.data?.data?.experianStatus === "FAILED") {
      if (res?.data?.data?.experianResponseForBA?.code !== 10) {
        yield put(
          updateExperianCustomError(
            res?.data?.data?.experianResponseForBA?.error
          )
        );
        yield put(updateShowExperianErrorPopup(true));
      } else {
        yield put(
          updateExperianResponse(res?.data?.data?.experianResponseForBA)
        );
        yield put(updateShowExperianMismatchScreen(true));
      }
      return;
    }
    const validationResult = res?.data?.data?.validationResult;
    if (validationResult) {
      const validationErrors = validationResult?.errors.map((err) => {
        return err.message;
      });
      const errMsg = validationErrors.join(" ");
      toast.error(errMsg);
    } else {
      yield put(updateNextStage(res?.data?.data?.nextStage));
    }
  } catch (err) {
    console.error(err);
    toast.error(
      "Something went wrong in saving your data. Please try again later."
    );
  }
}

function* handleSaveExperianMismatchData(action) {
  try {
    const res = yield call(saveExperianMismatchInfo, action.payload);
    if (!res?.data?.success) {
      console.error(res?.data?.message);
      toast.error(
        res?.data?.message ||
          "Unable to update experian details. Please try again later."
      );
      return;
    }
    if (res?.data?.data?.experianStatus === "FAILED") {
      if (res?.data?.data?.experianResponseForBA?.code !== 10) {
        yield put(
          updateExperianCustomError(
            res?.data?.data?.experianResponseForBA?.error
          )
        );
        yield put(updateShowExperianErrorPopup(true));
      } else {
        yield put(updateShowExperianMismatchScreen(true));
      }
      return;
    }
    const validationResult = res?.data?.data?.validationResult;
    if (validationResult) {
      const validationErrors = validationResult?.errors.map((err) => {
        return err.message;
      });
      const errMsg = validationErrors.join(" ");
      toast.error(errMsg);
    } else {
      yield put(updateNextStage(res?.data?.data?.nextStage));
    }
  } catch (err) {
    console.error(err);
    toast.error(
      "Something went wrong in saving your data. Please try again later."
    );
  }
}

function* personalDetailsSaga() {
  yield takeLatest(fetchApplicationDetails, handleFetchPersonalInfo);
  yield takeLatest(fetchCreditReportDocument, handleCreditReportFetch);
  yield takeLatest(savePersonalInfoData, handleSavePersonalInfoData);
  yield takeLatest(saveExperianMismatchData, handleSaveExperianMismatchData);
}

export default personalDetailsSaga;
