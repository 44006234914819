import apolloClient from "apolloClient";
import { gql } from "@apollo/client";
import ApplicationService from "services/applicationService";
import { inschoolStages } from "constant/inschoolStages";
export const requestUserDocumentsDetails = (slug) =>
  apolloClient.query({
    query: gql`
      query($slug: String) {
        application(slug: $slug) {
          id
          slug
          country
          cosignerAttempt
          course {
            name
            campus {
              college {
                name
              }
            }
          }
          profile {
            englishTest
            highestQualification
            aptitudeTest
            totalExperience
            firstName
            fathersTitle
            mothersTitle
            financialProof
            phone
            documents {
              id
              name
              keyName
              size
              url
              password
            }
          }
          cosigner1 {
            relationship
            documents {
              id
              name
              keyName
              size
              url
              password
            }
          }
          cosigner2 {
            relationship
            documents {
              id
              name
              keyName
              size
              url
              password
            }
          }
          offer {
            loanInterest
            loanAmount
            loanAmountInUSD
            loanTenure
            repaymentType
          }
          extraInformation {
            addressProof
          }
          applicationState {
            screen
            status
            underwriting
            subStage
          }
        }
      }
    `,
    variables: { slug },
    fetchPolicy: "no-cache",
  });

export const requestSaveDocumentsUpload = (data) => {
  const app = new ApplicationService(data?.slug);
  return app.update(data, inschoolStages.DOCUMENTS_UPLOAD);
};
