import { all } from "redux-saga/effects";
import personalDetailsSaga from "./PersonalDetails/redux/PersonalDetailsSaga";
import documentDetailsSaga from "./DocumentsUpload/redux/DocumentDetailsSaga";
import applicationDeclinedSaga from "./ApplicationDeclined/redux/ApplicationDeclinedSaga";
import documentReuploadSaga from "./DocumentsReupload/redux/DocumentReuploadDetailsSaga";

function* BASaga() {
  yield all([
    personalDetailsSaga(),
    documentDetailsSaga(),
    applicationDeclinedSaga(),
    documentReuploadSaga(),
  ]);
}

export default BASaga;
