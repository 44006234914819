import { call, put, select, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import {
  fetchProfessionalDetails,
  saveProfessionalDetails,
  updateAppScore,
  updateApplication,
  updateInitialValues,
  updateIsPageLoading,
  updateIsSaving,
  updateNextRelevantScreen,
} from "components/Form/ProfessionalDetailsForm/redux/ProfessionalDetailsSlice";
import { mutateInitialValues } from "components/Form/ProfessionalDetailsForm/redux/professionalDetailsHelper";
import {
  requestProfessionalDetails,
  requestSaveProfessionalDetails,
} from "components/Form/ProfessionalDetailsForm/redux/ProfessionalDetailsApi";
import { eventName, triggerInschoolTrackForm } from "lib/utils/events_utils";
import { trackEvent } from "scripts/segment";
import { fireEvent } from "utils/ga";
import { logEvent } from "components/Analytics/google-analytics";
import { underwritingStatus } from "constant/inschoolStages";
import Router from "next/router";

const getStore = (store) => store?.professionalDetails;

function* handleFetchProfessionalDetails(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestProfessionalDetails, action.payload);
    if (res.data) {
      yield put(
        updateInitialValues(mutateInitialValues(res?.data?.application))
      );
      yield put(updateApplication(res?.data?.application));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsPageLoading(false));
  }
}

function* handleSaveProfessionalDetails(action) {
  try {
    yield put(updateIsSaving(true));
    const res = yield call(requestSaveProfessionalDetails, action.payload);
    const { application } = yield select(getStore);
    yield call(triggerInschoolTrackForm, {
      values: action.payload,
      applicationId: application?.id,
      screen: application?.applicationState?.screen,
      nextStage: res?.success && res?.data?.nextStage,
      underwriting: res?.data?.underWritingStatus,
    });
    if (res.success) {
      yield call(
        logEvent,
        "Page Completed",
        `${application?.applicationState?.screen} completed`,
        `${application?.slug}`
      );
      yield call(trackEvent, eventName.IS_PROFESSIONAL_DETAILS_FILLED);
      yield call(fireEvent, eventName.IS_PROFESSIONAL_DETAILS_FILLED);
      yield put(updateNextRelevantScreen(res?.data?.nextStage));
      if (res?.data?.underWritingStatus === underwritingStatus.REJECTED) {
        Router.push(
          `/applications/${
            action.payload.slug
          }/${res?.data?.nextStage?.toLowerCase()}`
        );
      } else {
        yield put(updateAppScore(res?.data?.appScore));
      }
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsSaving(false));
  }
}

function* professionalDetailsSaga() {
  yield takeLatest(fetchProfessionalDetails, handleFetchProfessionalDetails);
  yield takeLatest(saveProfessionalDetails, handleSaveProfessionalDetails);
}

export default professionalDetailsSaga;
