import { generalValidation, pincodeValidation } from "lib/utils/validations";
import { Input } from "components/Inputs/TextField";

export const generateAddressFields = (addressType, isExtraCosigner = false) => {
  const addressCategory = {
    applicantCurrentAddress: "currentAddress",
    applicantPermanentAddress: "permanentAddress",
    cosignerCurrentAddress: isExtraCosigner
      ? "cosigner.currentAddress"
      : "cosignerCurrentAddress",
    cosignerPermanentAddress: isExtraCosigner
      ? "cosigner.permanentAddress"
      : "cosignerPermanentAddress",
  };
  return {
    pincodeField: {
      name: `${addressCategory[addressType]}.pincode`,
      component: Input,
      placeholder: "Enter Pincode",
      type: "number",
      maxLength: 6,
      label: "Pincode",
      validate: pincodeValidation,
    },
    stateField: {
      name: `${addressCategory[addressType]}.state`,
      component: Input,
      placeholder: "Enter State Name",
      label: "State",
      validate: generalValidation,
    },
    line1Field: {
      name: `${addressCategory[addressType]}.line1`,
      component: Input,
      placeholder: "Enter Street Address (House No, Building, Street, Area)",
      label: "Street Address (House No, Building, Street, Area)",
      validate: generalValidation,
    },
    line2Field: {
      name: `${addressCategory[addressType]}.line2`,
      component: Input,
      placeholder: "Enter Locality/Town",
      label: "Locality/Town",
      validate: generalValidation,
    },
    cityField: {
      name: `${addressCategory[addressType]}.city`,
      component: Input,
      placeholder: "Enter City/District",
      label: "City/District",
      validate: generalValidation,
    },
  };
};
