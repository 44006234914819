import { all, call, put, takeLatest } from "redux-saga/effects";
import { errorMessages } from "lib/utils/constants";
import {
  captureReferralDetails,
  referralDetailsActions,
} from "components/Referral/redux/ReferralSlice";
import { requestCaptureReferralDetails } from "components/Referral/redux/ReferralApi";

const {
  updateErrorMessage,
  updateIsReferralSuccess,
  updateIsSavingForm,
  updateShouldShowStatusModal,
} = referralDetailsActions;

function* handleCaptureReferralDetails(action) {
  try {
    yield put(referralDetailsActions.updateIsSavingForm(true));
    const res = yield call(requestCaptureReferralDetails, action.payload);
    if (res?.data?.success) {
      yield put(updateIsReferralSuccess(true));
    } else {
      yield all([
        put(
          updateErrorMessage(
            res?.data?.message || errorMessages.PLEASE_TRY_AGAIN
          )
        ),
        put(updateIsReferralSuccess(false)),
      ]);
    }
  } catch (err) {
    console.error(err);
    yield all([
      put(updateErrorMessage(errorMessages.PLEASE_TRY_AGAIN)),
      put(updateIsReferralSuccess(false)),
    ]);
  } finally {
    yield all([
      put(updateIsSavingForm(false)),
      put(updateShouldShowStatusModal(true)),
    ]);
  }
}

function* referralDetailsSaga() {
  yield takeLatest(captureReferralDetails, handleCaptureReferralDetails);
}

export default referralDetailsSaga;
