import axios from "axios";

export const requestOutstandingFees = (slug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=fees-outstanding/${slug}/pf-pending`,
  });

export const requestCreateOrder = (slug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=fees-outstanding/${slug}/pf-pending/create-order`,
  });
