import apolloClient from "apolloClient";
import { gql } from "@apollo/client";
import ApplicationService from "services/applicationService";
import { inschoolStages } from "constant/inschoolStages";

export const requestAddressDetails = (slug) =>
  apolloClient.query({
    query: gql`
      query($slug: String) {
        application(slug: $slug) {
          id
          slug
          cosignerAttempt
          season
          applicationStatus
          course {
            name
            campus {
              college {
                name
              }
            }
          }
          profile {
            firstName
            fathersTitle
            mothersTitle
            currentAddress {
              line1
              line2
              pincode
              state
              city
              country
            }
            permanentAddress {
              line1
              line2
              pincode
              state
              city
              country
            }
          }
          cosigner1 {
            firstName
            relationship
            currentAddress {
              line1
              line2
              pincode
              state
              city
              country
            }
            permanentAddress {
              line1
              line2
              pincode
              state
              city
              country
            }
          }
          cosigner2 {
            firstName
            relationship
            currentAddress {
              line1
              line2
              pincode
              state
              city
              country
            }
            permanentAddress {
              line1
              line2
              pincode
              state
              city
              country
            }
          }
          reference1 {
            name
            phone
            relationship
            relationshipInDetail
          }
          reference2 {
            name
            phone
            relationship
            relationshipInDetail
          }
          offer {
            loanInterest
            loanAmount
            loanAmountInUSD
            loanTenure
            repaymentType
          }
          applicationState {
            screen
            status
            underwriting
            subStage
          }
        }
      }
    `,
    variables: { slug },
    fetchPolicy: "no-cache",
  });

export const requestSaveAddressDetails = (data) => {
  const app = new ApplicationService(data?.slug);
  return app.update(data, inschoolStages.ADDRESS_DETAILS);
};
