import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialValues: {},
  application: null,
  isSaving: false,
  isPageLoading: false,
  nextRelevantScreen: "",
  appScore: null,
};

export const fetchProfessionalDetails = createAction(
  "professionalDetails/fetchProfessionalDetails"
);

export const saveProfessionalDetails = createAction(
  "professionalDetails/saveProfessionalDetails"
);

const professionalDetailsSlice = createSlice({
  name: "professionalDetails",
  initialState,
  reducers: {
    updateInitialValues(state, action) {
      return { ...state, initialValues: action.payload };
    },
    updateApplication(state, action) {
      return { ...state, application: action.payload };
    },
    updateIsSaving(state, action) {
      return { ...state, isSaving: action.payload };
    },
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
    updateNextRelevantScreen(state, action) {
      return { ...state, nextRelevantScreen: action.payload };
    },
    updateAppScore(state, action) {
      return { ...state, appScore: action.payload };
    },
  },
});

export const {
  updateInitialValues,
  updateApplication,
  updateIsSaving,
  updateIsPageLoading,
  updateNextRelevantScreen,
  updateAppScore,
} = professionalDetailsSlice.actions;
export default professionalDetailsSlice.reducer;
