import cloneDeep from "lodash/cloneDeep";

export const mutateValues = (payload) => {
  const docs = cloneDeep(payload);
  const documents = Object.keys(docs).reduce((a, c) => {
    if (Array.isArray(payload[c])) {
      payload[c].forEach((file) => {
        const obj = { ...file };
        obj.keyName = c;
        a.push(obj);
      });
    } else {
      const obj = { ...payload[c] };
      obj.keyName = c;
      a.push(obj);
    }
    return a;
  }, []);
  return { documents };
};
