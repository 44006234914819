import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialValues: {},
  application: {},
  isSwitchingStage: false,
  isSaving: false,
  isPageLoading: false,
};

export const fetchOfferDetails = createAction("offerDetails/fetchOfferDetails");

export const switchToCosignerDetailsStage = createAction(
  "offerDetails/switchToCosignerDetailsStage"
);

export const saveOfferDetails = createAction("offerDetails/saveOfferDetails");

const offerDetailsSlice = createSlice({
  name: "offerDetails",
  initialState,
  reducers: {
    updateInitialValues(state, action) {
      return { ...state, initialValues: action.payload };
    },
    updateApplication(state, action) {
      return { ...state, application: action.payload };
    },
    updateIsSwitchingStage(state, action) {
      return { ...state, isSwitchingStage: action.payload };
    },
    updateIsSaving(state, action) {
      return { ...state, isSaving: action.payload };
    },
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
  },
});

export const {
  updateInitialValues,
  updateApplication,
  updateIsSwitchingStage,
  updateIsSaving,
  updateIsPageLoading,
} = offerDetailsSlice.actions;
export default offerDetailsSlice.reducer;
