import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchApplicantDocuments,
  updateApplicationData,
  updateNextStage,
  saveDocumentsInfo,
} from "./DocumentDetailsSlice";
import {
  requestApplicantDocuments,
  saveDocumentsData,
} from "./DocumentDetailsApi";
import cloneDeep from "lodash/cloneDeep";
import { mutateDocuments, parseBankDocuments } from "./DocumentDetailsHelper";
import { toast } from "react-toastify";

function* handleFetchApplicantDocuments(action) {
  try {
    const res = yield call(requestApplicantDocuments, action.payload);
    const parsedDocuments = parseBankDocuments(
      res?.data?.bankApplication?.applicant?.documents
    );
    const mutatedApplicant = mutateDocuments(
      cloneDeep(res?.data?.bankApplication?.applicant)
    );
    yield put(
      updateApplicationData({
        applicant: mutatedApplicant,
        documents: parsedDocuments,
      })
    );
  } catch (err) {
    console.error(err);
  }
}

function* handleSaveDocumentsInfo(action) {
  try {
    const res = yield call(saveDocumentsData, action.payload);
    if (!res?.data?.success) {
      console.error(res?.data?.message);
      toast.error(
        res?.data?.message ||
          "Unable to process your documents at the moment. Please try again later."
      );
      return;
    }
    const validationResult = res?.data?.data?.validationResult;
    if (validationResult) {
      const validationErrors = validationResult?.errors.map((err) => {
        return err.message;
      });
      const errMsg = validationErrors.join(" ");
      toast.error(errMsg);
    } else {
      yield put(updateNextStage(res?.data?.data?.nextStage));
    }
  } catch (err) {
    console.error(err);
    toast.error(
      "Something went wrong in saving your data. Please try again later."
    );
  }
}

function* documentDetailsSaga() {
  yield takeLatest(fetchApplicantDocuments, handleFetchApplicantDocuments);
  yield takeLatest(saveDocumentsInfo, handleSaveDocumentsInfo);
}

export default documentDetailsSaga;
