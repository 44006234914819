import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  feesData: null,
  loanAndDisbursementDetails: null,
};

export const fetchLoanAndDisbursementDetails = createAction(
  "disbursements/fetchLoanAndDisbursementDetails"
);

const DisbursementsSlice = createSlice({
  name: "disbursements",
  initialState,
  reducers: {
    updateFeesData(state, action) {
      return { ...state, feesData: action.payload };
    },
    updateLoanAndDisbursementDetails(state, action) {
      return { ...state, loanAndDisbursementDetails: action.payload };
    },
  },
});

export const {
  updateFeesData,
  updateLoanAndDisbursementDetails,
} = DisbursementsSlice.actions;
export default DisbursementsSlice.reducer;
