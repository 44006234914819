import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  createOrder,
  extraChargeCollectionActions,
  fetchFeeElements,
  fetchOutstandingFees,
} from "components/EducationLoan/ExtraChargeCollection/redux/ExtraChargeCollectionSlice";
import {
  requestCreateOrder,
  requestOutstandingFees,
} from "components/EducationLoan/ExtraChargeCollection/redux/ExtraChargeCollectionApi";
import { errorMessages } from "lib/utils/constants";
import { requestFeeElements } from "components/EducationLoan/SanctionGenerated/redux/SanctionGeneratedApi";

function* handleFetchFeeElements(action) {
  try {
    yield put(extraChargeCollectionActions.updateIsPageLoading(true));
    const res = yield call(requestFeeElements, action.payload);
    if (res.data) {
      yield all([
        put(
          extraChargeCollectionActions.updateApplication(res?.data?.application)
        ),
        put(
          extraChargeCollectionActions.updatePreviouslyOfferedLoanAmount(
            res?.data?.loanAmountChangeLog?.previousLoanAmount
          )
        ),
      ]);
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(extraChargeCollectionActions.updateIsPageLoading(false));
  }
}

function* handleFetchOutstandingFees(action) {
  try {
    const res = yield call(requestOutstandingFees, action.payload);
    if (res?.data?.success) {
      yield put(extraChargeCollectionActions.updateFeesData(res?.data?.data));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  }
}

function* handleCreateOrder(action) {
  try {
    yield put(extraChargeCollectionActions.updateIsCreatingOrder(true));
    const res = yield call(requestCreateOrder, action.payload);
    if (res?.data?.success) {
      yield all([
        put(
          extraChargeCollectionActions.updateOrderId(res?.data?.data.orderId)
        ),
        put(
          extraChargeCollectionActions.updateClientSecret(
            res?.data?.data?.clientSecret
          )
        ),
      ]);
      yield put(extraChargeCollectionActions.updatePaymentModal(true));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(extraChargeCollectionActions.updateIsCreatingOrder(false));
  }
}

function* extraChargeCollectionSaga() {
  yield takeLatest(fetchFeeElements, handleFetchFeeElements);
  yield takeLatest(fetchOutstandingFees, handleFetchOutstandingFees);
  yield takeLatest(createOrder, handleCreateOrder);
}

export default extraChargeCollectionSaga;
