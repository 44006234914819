const initState = {
  active: "education",
};

const applicationListReducer = (state = initState, action) => {
  if (action.type === "CHANGE_TAB") {
    return {
      ...state,
      active: action.payload,
    };
  } else return state;
};

export default applicationListReducer;
