import apolloClient from "apolloClient";
import { gql } from "@apollo/client";
import ApplicationService from "services/applicationService";
import { inschoolStages } from "constant/inschoolStages";

export const requestPersonalDetails = (slug) =>
  apolloClient.query({
    query: gql`
      query($slug: String!) {
        application(slug: $slug) {
          id
          slug
          profile {
            firstName
            middleName
            lastName
            dob
            gender
            pan
            phone
            isPhoneVerified
            fathersName
            mothersName
            consent
            email
            fathersTitle
            mothersTitle
            currentAddress {
              pincode
            }
            experianStatus
            experianResponse {
              status
              code
              error
              maskedPhone
            }
          }
          course {
            name
            campus {
              college {
                name
              }
            }
          }
          applicationState {
            screen
            status
            underwriting
            subStage
          }
        }
      }
    `,
    variables: { slug },
    fetchPolicy: "no-cache",
  });

export const requestSavePersonalDetails = (data) => {
  const app = new ApplicationService(data?.slug);
  return app.update(data, inschoolStages.PERSONAL_DETAILS);
};
