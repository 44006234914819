import { getUtmData } from "../lib/utils/utm_utils";
import {
  generateFormId,
  generatePageName,
  getEventPayload,
  getUserPayload,
  pageName,
} from "../lib/utils/events_utils";
import { getUserIdentifier } from "../lib/utils/getUserIdentifier";
import { sessionStorageUtils } from "lib/utils/storageUtils";

const utmDetails = getUtmData(null);

export const segmentReset = () => {
  if (window.analytics) {
    window.analytics.reset();
  }
};

export const trackPage = (name) => {
  try {
    const eventName = "Page Viewed";
    const referrer = sessionStorageUtils?.getItem("prevPath") || "";
    const props = {
      pageName: name || "NA",
      utmCampaign: utmDetails?.utmCampaign || "NA",
      utmSource: utmDetails?.utmSource || "NA",
      utmTerm: utmDetails?.utmTerm || "NA",
      adId: utmDetails?.adId || "NA",
      referrer: referrer === "null" ? document?.referrer : referrer,
    };
    if (window.analytics) {
      window.analytics.track(eventName, props);
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackClick = (name, props) => {
  try {
    const referrer = sessionStorageUtils?.getItem("prevPath") || "";
    const eventName = "Click";
    const data = {
      pageName: name || "NA",
      widgetFormat: props.widgetFormat || "NA",
      widgetName: props.widgetName || "NA",
      contentName: props.contentName || "NA",
      contentFormat: props.contentFormat || "NA",
      utmCampaign: utmDetails?.utmCampaign || "NA",
      utmSource: utmDetails?.utmSource || "NA",
      utmTerm: utmDetails?.utmTerm || "NA",
      adId: utmDetails?.adId || "NA",
      referrer: referrer === "null" ? document?.referrer : referrer,
    };

    if (window.analytics) {
      window.analytics.track(eventName, data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackIdentity = (id, props) => {
  try {
    if (window.analytics) {
      window.analytics.identify(id, props);
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackEvent = (eventName, props) => {
  try {
    if (window.analytics) {
      window.analytics.track(eventName, props);
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackLogIn = async (props) => {
  try {
    const authId = await getUserIdentifier();
    const eventName = "Log In Successful";
    const referrer = sessionStorageUtils?.getItem("prevPath") || "";
    if (window.analytics) {
      const eventPayload = {
        phoneNumber: props?.mobile || "NA",
        pageName: props?.pageName || "NA",
        utmCampaign: utmDetails?.utmCampaign || "NA",
        utmSource: utmDetails?.utmSource || "NA",
        utmTerm: utmDetails?.utmTerm || "NA",
        adId: utmDetails?.adId || "NA",
        loginType: props?.loginType || "NA",
        referrer: referrer === "null" ? document?.referrer : referrer,
      };
      const userPayload = {
        phone: props?.mobile,
        email: props?.email,
      };
      await window.analytics.identify(authId, userPayload);
      await window.analytics.track(eventName, eventPayload);
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackForm = async (eventName, props) => {
  try {
    const authId = await getUserIdentifier();
    if (window.analytics) {
      const eventPayload = getEventPayload(props);
      const userPayload = getUserPayload(props);
      window.analytics.identify(authId, userPayload);
      window.analytics.track(eventName, eventPayload);
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackCourseDetailsStatus = async (props) => {
  try {
    const eventName = "Course";
    const referrer = sessionStorageUtils?.getItem("prevPath") || "";
    const authId = await getUserIdentifier();
    const data = {
      product: props?.product || "NA",
      appId: props?.applicationId || "NA",
      pageName: props?.pageName || "NA",
      referrer: referrer === "null" ? document?.referrer : referrer,
      courseStatus: props?.courseStatus || "NA",
      courseRejectionReason: props?.courseRejectionReason || "NA",
    };
    const userPayload = {
      university: props?.university || "NA",
      campus: props?.campus || "NA",
      course: props?.course || "NA",
      country: props?.country || "NA",
      courseDuration: props?.duration,
      courseSeason: props?.season || "NA",
    };
    if (window.analytics) {
      window.analytics.identify(authId, userPayload);
      window.analytics.track(eventName, data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackFileUpload = (props) => {
  try {
    const eventName = "File Uploaded";
    const referrer = sessionStorageUtils?.getItem("prevPath") || "";
    const data = {
      pageName: generatePageName(),
      product: props?.product || "NA",
      fileType: props?.fileType || "NA",
      fileCategory: props?.fileCategory || "NA",
      fileName: props?.fileName || "NA",
      formId: generateFormId(),
      referrer: referrer === "null" ? document?.referrer : referrer,
    };

    if (window.analytics) {
      window.analytics.track(eventName, data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackOtpVerification = (props) => {
  try {
    const eventName = "OTP Verification";
    const referrer = sessionStorageUtils?.getItem("prevPath") || "";
    const data = {
      verificationStatus: props?.verificationStatus || "NA",
      product: props?.product || "NA",
      otpType: props?.otpType || "NA",
      appId: props?.appId,
      pageName: generatePageName(),
      referrer: referrer === "null" ? document?.referrer : referrer,
    };

    if (window.analytics) {
      window.analytics.track(eventName, data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackBAForm = async (eventName, props) => {
  try {
    const authId = await getUserIdentifier();
    if (window.analytics) {
      const eventPayload = {
        product: props?.product || "NA",
        pageName: props?.pageName,
        utmCampaign: utmDetails?.utmCampaign || "NA",
        utmSource: utmDetails?.utmSource || "NA",
        utmTerm: utmDetails?.utmTerm || "NA",
        adId: utmDetails?.adId || "NA",
        referrer: document?.referrer || "NA",
        formId: props?.formId || "NA",
        creditCardConsent: props?.creditCardConsent || "NA",
      };
      const trackEventPromise = new Promise((resolve) => {
        window.analytics.track(eventName, eventPayload, {}, () => {
          resolve(true);
        });
      });
      if (props?.pageName === pageName.BA_PERSONAL_DETAILS) {
        const userPayload = {
          email: props?.email,
          firstName: props?.firstName,
          lastName: props?.lastName,
        };
        const identifyEventPromise = new Promise((resolve) => {
          window.analytics.identify(authId, userPayload, {}, () => {
            resolve(true);
          });
        });
        return Promise.all([trackEventPromise, identifyEventPromise]);
      } else {
        return trackEventPromise;
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackCreditCardToggle = async (eventName, props) => {
  try {
    if (window.analytics) {
      const data = {
        utmCampaign: utmDetails?.utmCampaign || "NA",
        utmSource: utmDetails?.utmSource || "NA",
        utmTerm: utmDetails?.utmTerm || "NA",
        adId: utmDetails?.adId || "NA",
        referrer: document?.referrer || "NA",
        toggle: props?.toggle || "NA",
      };
      window.analytics.track(eventName, data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackBAClick = async (name, props) => {
  try {
    const referrer = sessionStorageUtils?.getItem("prevPath") || "";
    const eventName = "Click";
    const data = {
      pageName: name || "NA",
      widgetFormat: props.widgetFormat || "NA",
      widgetName: props.widgetName || "NA",
      contentName: props.contentName || "NA",
      contentFormat: props.contentFormat || "NA",
      utmCampaign: utmDetails?.utmCampaign || "NA",
      utmSource: utmDetails?.utmSource || "NA",
      utmTerm: utmDetails?.utmTerm || "NA",
      adId: utmDetails?.adId || "NA",
      referrer: referrer === "null" ? document?.referrer : referrer,
    };
    if (window.analytics) {
      return new Promise((resolve) => {
        window.analytics.track(eventName, data, {}, () => {
          resolve(true);
        });
      });
    }
  } catch (error) {
    console.error(error);
  }
};
