import axios from "axios";
import { Field } from "react-final-form";
import { AsyncCreatableSelect } from "components/Inputs/MultiSelect";
import { Input } from "components/Inputs/TextField";
import { Section } from "components/Form/sections";

const WorkExperienceSection = ({ values }) => (
  <Section paddingTop>
    <div
      className={
        values?.currentEmployment?.totalWorkExperienceInMonths > 0
          ? "md:mb-8 md:-mx-2"
          : ""
      }
    >
      <div
        className={` ${
          values?.currentEmployment?.totalWorkExperienceInMonths > 0
            ? "mb-6 "
            : ""
        } md:w-full md:px-2`}
      >
        <Field
          name="currentEmployment.totalWorkExperienceInMonths"
          label="Total work experience (in months)"
          placeholder="Enter Total work experience (in months)"
          helperText="Ex: 13"
          component={Input}
          type="number"
          validate={(value) => {
            if (!value && value !== 0) return "This field is required";
            if (value < 0 || value > 1200) return "Please enter a valid value";
            if (value && value.toString().includes("."))
              return "Decimal not allowed.Give experience in months";
          }}
        />
      </div>
      {values?.currentEmployment?.totalWorkExperienceInMonths > 0 && (
        <div className="mb-6 md:w-full md:px-2">
          <Field
            name="currentEmployment.currentAnnualSalary"
            label="Monthly in-hand salary in INR"
            placeholder="Enter your monthly in-hand salary in INR"
            component={Input}
            type="number"
            helperText="Monthly salary credited to your account (exclude one time bonus)"
            validate={(value) => {
              if (!value && value !== 0) return "This field is required";
              if (value.toString().includes(".")) return "Decimal not allowed";
              if (value < 0 || value > 100000000)
                return "Please enter a valid value";
            }}
          />
        </div>
      )}
    </div>
    {Number(values?.currentEmployment?.totalWorkExperienceInMonths) > 0 && (
      <div className="md:flex md:-mx-2">
        <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
          <Field
            name="currentEmployment.employer"
            component={AsyncCreatableSelect}
            label="Most recent employer"
            placeholder="Enter Most recent employer"
            noOptionsMessage={() => "Please type the company name"}
            loadingMessage={() => "Loading company list..."}
            loadOptions={async (companySearched) => {
              if (companySearched.length > 1) {
                const {
                  data: { companies },
                } = await axios(`/api/companies?term=${companySearched}`);
                return companies.map((c) => ({
                  label: c,
                  value: c,
                }));
              }
            }}
            validate={(v) => {
              if (!v) return "Most recent employer is required!";
            }}
          />
        </div>
        <div className="md:w-1/2 md:px-2">
          <Field
            name="currentEmployment.designation"
            label="Designation"
            placeholder="Enter Designation"
            component={Input}
            type="text"
            validate={(value) => {
              if (!value) return "Designation is required";
            }}
          />
        </div>
      </div>
    )}
  </Section>
);

export default WorkExperienceSection;
