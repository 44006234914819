import axios from "axios";
import { errorMessages } from "lib/utils/constants";
import { toast } from "react-toastify";

export const requestAllDisbursementsDetails = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/getAllDisbursements/${applicationSlug}`,
  });

export const requestDisbursementDetails = (disbursementSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/getDisbursementDetails/${disbursementSlug}`,
  });

export const requestCreateDisbursement = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/create/${applicationSlug}`,
  });

export const requestLoanAndDisbursementDetails = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/fetchLoanAndDisbursementDetails/${applicationSlug}`,
  });

export const requestTransactionHistory = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/transaction_history/${applicationSlug}`,
  });

export const requestBankAccountType = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/getBankAccountTypes/${applicationSlug}`,
  });

export const requestDisbursementState = (disbursementSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/getState/${disbursementSlug}`,
  });

export const requestLeadOwner = (userPhoneNumber) =>
  axios.request({
    method: "get",
    url: `/api/route?path=application-post-sanction/${userPhoneNumber}/get-lead-owner`,
  });

export const requestOfferUpdationReason = (disbursementSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/fetchUpdatedReason/${disbursementSlug}`,
  });

export const requestStudentSubmittedFundDetails = (disbursementSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/fetchStudentSubmittedRequest/${disbursementSlug}`,
  });

export const requestSaveFundDetails = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_request/submit",
    data,
  });

export const requestSaveDocumentDetails = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_request/uploadDocument",
    data,
  });

export const requestApproveOrRejectOffer = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_request/acceptOrRejectOffer",
    data,
  });

export const requestSubmitStudentRejectionReason = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_request/submitRejectionReason",
    data,
  });

export const requestUpdateFundDetails = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_request/update_request",
    data,
  });

export const requestFetchActiveDisbrusements = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/fetchActiveDisbursements/${applicationSlug}`,
  });

export const requestSeason = (applicationSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/${applicationSlug}/seasons`,
  });

export const requestDocuments = (slug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_request/documents/metadata/${slug}`,
  });

export const requestProcessingFeesData = (slug) => {
  return axios.request({
    method: "get",
    url: `/api/route?path=fees-outstanding/${slug}/fee-status`,
  });
};

export const requestDisbursementActivityState = async (slug) => {
  try {
    const res = await axios.get(
      `/api/route?path=disbursement/fetch-activity-state/${slug}`
    );
    if (res?.data?.success) {
      return res?.data?.data;
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.log(error);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  }
};
