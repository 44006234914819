import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  application: null,
  nextStage: "",
  isPageLoading: true,
};

export const fetchFeeElements = createAction("pfPaidDetails/fetchFeeElements");

export const moveStageToPostSanctionDocPending = createAction(
  "pfPaidDetails/moveStageToPostSanctionDocPending"
);

const pfPaidSlice = createSlice({
  name: "pfPaidDetails",
  initialState,
  reducers: {
    updateApplication(state, action) {
      return { ...state, application: action.payload };
    },
    updateNextStage(state, action) {
      return { ...state, nextStage: action.payload };
    },
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
  },
});

export const {
  updateApplication,
  updateNextStage,
  updateIsPageLoading,
} = pfPaidSlice.actions;
export default pfPaidSlice.reducer;
