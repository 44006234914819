import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  documentInfo: {},
  nextStage: null,
};

export const fetchApplicantDocuments = createAction(
  "documentDetails/documentInfo"
);

export const saveDocumentsInfo = createAction(
  "documentDetails/saveDocumentInfo"
);

const documentDetailsSlice = createSlice({
  name: "documentDetails",
  initialState,
  reducers: {
    updateApplicationData(state, action) {
      const { applicant } = action.payload;
      delete applicant.visaType;
      return { ...state, documentInfo: applicant };
    },
    updateNextStage(state, action) {
      return { ...state, nextStage: action.payload };
    },
  },
});

export const {
  updateApplicationData,
  updateNextStage,
} = documentDetailsSlice.actions;
export default documentDetailsSlice.reducer;
