import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isReferralSuccess: false,
  isSavingForm: false,
  errorMessage: "",
  shouldShowStatusModal: false,
};

export const captureReferralDetails = createAction(
  "referralDetails/captureReferralDetails"
);

const referralDetails = createSlice({
  name: "referralDetails",
  initialState,
  reducers: {
    updateIsReferralSuccess(state, action) {
      return { ...state, isReferralSuccess: action.payload };
    },
    updateIsSavingForm(state, action) {
      return { ...state, isSavingForm: action.payload };
    },
    updateErrorMessage(state, action) {
      return { ...state, errorMessage: action.payload };
    },
    updateShouldShowStatusModal(state, action) {
      return { ...state, shouldShowStatusModal: action.payload };
    },
  },
});

export const referralDetailsActions = { ...referralDetails.actions };
export default referralDetails.reducer;
