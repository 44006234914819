import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  documentReuploadInfo: {},
  showModal: null,
  nextStage: null,
};

export const fetchApplicantDocumentsForReupload = createAction(
  "rejectedDocumentDetails/documentReuploadInfo"
);

export const saveDocumentsReuploadInfo = createAction(
  "rejectedDocumentDetails/documentReuploadSave"
);

const rejectedDocumentDetailsSlice = createSlice({
  name: "rejectedDocumentDetails",
  initialState,
  reducers: {
    setApplicationData(state, action) {
      const { applicant } = action.payload;
      return { ...state, documentReuploadInfo: applicant };
    },
    updateExperianGenerateStepsModalDisplay(state, action) {
      return { ...state, showModal: action.payload };
    },
    updateNextStage(state, action) {
      return { ...state, nextStage: action.payload };
    },
  },
});

export const {
  setApplicationData,
  updateExperianGenerateStepsModalDisplay,
  updateNextStage,
} = rejectedDocumentDetailsSlice.actions;
export default rejectedDocumentDetailsSlice.reducer;
