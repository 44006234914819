import { getLeapCookie, setLeapCookie } from "./leap-cookies";

export function getUtmData(ctx) {
  let utmSource, utmCampaign, utmTerm, refereeCode, adId, userContext;
  if (getLeapCookie(ctx, "utmSource")) {
    utmSource = getLeapCookie(ctx, "utmSource");
  }
  if (getLeapCookie(ctx, "utmCampaign")) {
    utmCampaign = getLeapCookie(ctx, "utmCampaign");
  }

  if (getLeapCookie(ctx, "refereeCode")) {
    refereeCode = getLeapCookie(ctx, "refereeCode");
  }

  utmTerm = getLeapCookie(ctx, "utmTerm");

  if (getLeapCookie(ctx, "adId")) {
    adId = getLeapCookie(ctx, "adId");
  }

  if (getLeapCookie(ctx, "userContext")) {
    userContext = getLeapCookie(ctx, "userContext");
  }
  return {
    utmSource,
    utmCampaign,
    utmTerm,
    refereeCode,
    adId,
    userContext,
  };
}

export function saveUtmData(ctx, utmData) {
  if (utmData.utmSource) {
    setLeapCookie(ctx, "utmSource", utmData.utmSource);
  }
  if (utmData.utmCampaign) {
    setLeapCookie(ctx, "utmCampaign", utmData.utmCampaign);
  }
  if (utmData.utmTerm) {
    setLeapCookie(ctx, "utmTerm", utmData.utmTerm);
  }
  if (utmData.refereeCode) {
    setLeapCookie(ctx, "refereeCode", utmData.refereeCode);
  }
  if (utmData.adId) {
    setLeapCookie(ctx, "adId", utmData.adId);
  }
  if (utmData.userContext) {
    setLeapCookie(ctx, "userContext", utmData.userContext);
  }
}
