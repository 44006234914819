import { call, put, select, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import {
  requestCosignerDocumentsDetails,
  requestSaveCosignerDocumentsUpload,
} from "components/Form/CosignerDocumentsUpload/redux/CosignerDocumentsUploadApi";
import {
  fetchCosignerDocumentsUpload,
  saveCosignerDocumentsUpload,
  updateApplication,
  updateInitialValues,
  updateIsPageLoading,
  updateIsSaving,
} from "components/Form/CosignerDocumentsUpload/redux/CosignerDocumentsUploadSlice";
import Router from "next/router";
import { eventName, triggerInschoolTrackForm } from "lib/utils/events_utils";
import { trackEvent } from "scripts/segment";
import { fireEvent } from "utils/ga";
import { logEvent } from "components/Analytics/google-analytics";

const getStore = (store) => store?.cosignerDocumentsUpload;

function* handleFetchCosignerDocumentsUpload(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestCosignerDocumentsDetails, action.payload);
    if (res.data) {
      yield put(
        updateInitialValues(res?.data?.application?.profile?.documents)
      );
      yield put(updateApplication(res?.data?.application));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsPageLoading(false));
  }
}

function* handleSaveCosignerDocumentsUpload(action) {
  try {
    yield put(updateIsSaving(true));
    const res = yield call(requestSaveCosignerDocumentsUpload, action.payload);
    const { application } = yield select(getStore);
    yield call(triggerInschoolTrackForm, {
      values: action.payload,
      applicationId: application?.id,
      screen: application?.applicationState?.screen,
      nextStage: res?.success && res?.data,
    });
    if (res.success) {
      yield call(
        logEvent,
        "Page Completed",
        `${application?.applicationState?.screen} completed`,
        `${application?.slug}`
      );
      yield call(trackEvent, eventName.IS_OFFER_CONFIRMATION);
      yield call(fireEvent, eventName.IS_OFFER_CONFIRMATION);
      Router.push(
        `/applications/${action.payload.slug}/${res?.data?.toLowerCase()}`
      );
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsSaving(false));
  }
}

function* cosignerDocumentsUploadSaga() {
  yield takeLatest(
    fetchCosignerDocumentsUpload,
    handleFetchCosignerDocumentsUpload
  );
  yield takeLatest(
    saveCosignerDocumentsUpload,
    handleSaveCosignerDocumentsUpload
  );
}

export default cosignerDocumentsUploadSaga;
