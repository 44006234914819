import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  visaRejected: null,
};

export const fetchApplicantInfo = createAction("declinedDetails/applicantInfo");

const applicantDeclinedSlice = createSlice({
  name: "declinedDetails",
  initialState,
  reducers: {
    updateLoadingToggle(state, action) {
      return { ...state, loading: action.payload };
    },
    updateVisaRejectedFlag(state, action) {
      return { ...state, visaRejected: action.payload };
    },
  },
});

export const {
  updateLoadingToggle,
  updateVisaRejectedFlag,
} = applicantDeclinedSlice.actions;
export default applicantDeclinedSlice.reducer;
