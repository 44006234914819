import { parseCookies, setCookie, destroyCookie } from "nookies";

const MAX_AGE = 60 * 60 * 24 * 30; // 30 days

export function setLeapCookie(ctx, key, data) {
  setCookie(ctx, key, data, {
    maxAge: MAX_AGE,
    expires: new Date(Date.now() + MAX_AGE * 1000),
    httpOnly: false,
    secure: process.env.NODE_ENV === "production",
    path: "/",
    sameSite: "lax",
  });
}

export function removeLeapCookie(ctx, key) {
  destroyCookie(ctx, key, {
    maxAge: -1,
    path: "/",
  });
}

export function getLeapCookie(ctx, key) {
  const cookies = parseCookies(ctx);
  return cookies[key];
}
