import { imageBasePath } from "lib/utils/imageUtils";

export const CountryCode = {
  INDIA: "INDIA",
  USA: "USA",
};

export const { INDIA, USA } = CountryCode;

export const countryData = {
  [INDIA]: {
    code: "+91",
    flag: `${imageBasePath}/assets/images/ebix/india-flag.svg`,
  },
  [USA]: {
    code: "+1",
    flag: `${imageBasePath}/assets/images/ebix/us-flag.svg`,
  },
};

export const countries = [
  {
    label: "India",
    value: INDIA,
  },
  {
    label: "USA",
    value: USA,
  },
];
