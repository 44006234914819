import { requestLeadOwner } from "components/EducationLoan/Disbursements/redux/DisbursementsApi";
import { ContactSectionVariants } from "components/shared/ContactDetailsSection/constants";
import ContactDetailsCard from "components/shared/ContactDetailsSection/ContactDetailsCard";
import { useFetchUser } from "lib/user";
import { useEffect, useState } from "react";

export const ContactDetailsSection = ({
  customCss = "my-16 mx-4 md:mx-0",
  variant = ContactSectionVariants.PRIMARY,
  isPostOkToDisburseStage = false,
}) => {
  const [leadOwnerDetails, setLeadOwnerDetails] = useState(null);
  const { user } = useFetchUser();

  const { grievanceLsqUserEscalationEmail, postDisbursalRmDetails, rmDetails } =
    leadOwnerDetails || {};

  const { firstName, lastName, email, phone } =
    (isPostOkToDisburseStage ? postDisbursalRmDetails : rmDetails) || {};

  const fetchLeadOwner = async (phone) => {
    try {
      const leadOwnerResponse = await requestLeadOwner(phone);
      if (leadOwnerResponse?.data?.success) {
        setLeadOwnerDetails(leadOwnerResponse?.data?.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (user) {
      fetchLeadOwner(user?.mobile);
    }
  }, [user]);

  if (!leadOwnerDetails) return null;

  return (
    <div
      className={`flex gap-4 items-stretch flex-col md:flex-row ${customCss}`}
    >
      <ContactDetailsCard
        title="Still have questions?"
        subText="We're always here to help! Feel free to reach out to your relationship manager"
        name={`${firstName} ${lastName}`}
        email={email}
        phone={phone}
        variant={variant}
      />
      <ContactDetailsCard
        title="Want to escalate your issue?"
        subText="To escalate it further, contact our Sales VP at the provided email"
        email={grievanceLsqUserEscalationEmail}
        variant={variant}
      />
    </div>
  );
};

export default ContactDetailsSection;
