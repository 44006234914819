import { Field } from "react-final-form";
import { gpaBaseOptions } from "components/EducationLoan/SelectOptions";
import { MultiSelect } from "components/Inputs/MultiSelect";
import { Input } from "components/Inputs/TextField";

const GpaBase = ({ level }) => (
  <div className="md:flex md:-mx-2">
    <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
      <Field
        name={`${level?.toLowerCase()}GraduationDetails.gpaBase`}
        component={MultiSelect}
        label="Select a GPA Base"
        placeholder="Select a GPA Base"
        options={gpaBaseOptions}
        validate={(value) => {
          if (!value) return "Please choose the appropriate GPA scale";
        }}
        isSearchable
      />
    </div>
    <div className="md:w-1/2 md:px-2">
      <Field
        name={`${level?.toLowerCase()}GraduationDetails.gpa`}
        label="Enter GPA"
        component={Input}
        type="number"
      />
    </div>
  </div>
);

export default GpaBase;
