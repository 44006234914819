import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentModal: false,
  processingPayment: false,
  orderId: "",
  clientSecret: "",
  feesData: null,
  isPaymentFailed: false,
  paymentFailureReason: {},
  application: null,
  isCreatingOrder: false,
  isPageLoading: true,
  nextStage: "",
};

export const fetchFeeElements = createAction(
  "sanctionGeneratedDetails/fetchFeeElements"
);
export const fetchOutstandingFees = createAction(
  "sanctionGeneratedDetails/fetchOutstandingFees"
);
export const createOrder = createAction("sanctionGeneratedDetails/createOrder");
export const changeStageToPFPending = createAction(
  "sanctionGeneratedDetails/changeStageToPFPending"
);
export const changeStageToPFPaid = createAction(
  "sanctionGeneratedDetails/changeStageToPFPaid"
);

const sanctionGeneratedDetailsSlice = createSlice({
  name: "sanctionGeneratedDetails",
  initialState,
  reducers: {
    updatePaymentModal(state, action) {
      return { ...state, paymentModal: action.payload };
    },
    updateProcessingPayment(state, action) {
      return { ...state, processingPayment: action.payload };
    },
    updateOrderId(state, action) {
      return { ...state, orderId: action.payload };
    },
    updateClientSecret(state, action) {
      return { ...state, clientSecret: action.payload };
    },
    updateFeesData(state, action) {
      return { ...state, feesData: action.payload };
    },
    updateIsPaymentFailed(state, action) {
      return { ...state, isPaymentFailed: action.payload };
    },
    updateIsSFFeesPaid(state, action) {
      return { ...state, isSFFeesPaid: action.payload };
    },
    updatePaymentFailureReason(state, action) {
      return { ...state, paymentFailureReason: action.payload };
    },
    updateApplication(state, action) {
      return { ...state, application: action.payload };
    },
    updateIsCreatingOrder(state, action) {
      return { ...state, isCreatingOrder: action.payload };
    },
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
    updateNextStage(state, action) {
      return { ...state, nextStage: action.payload };
    },
  },
});

export const {
  updatePaymentModal,
  updateProcessingPayment,
  updateOrderId,
  updateClientSecret,
  updateFeesData,
  updateIsPaymentFailed,
  updateIsSFFeesPaid,
  updatePaymentFailureReason,
  updateApplication,
  updateIsCreatingOrder,
  updateIsPageLoading,
  updateNextStage,
} = sanctionGeneratedDetailsSlice.actions;
export default sanctionGeneratedDetailsSlice.reducer;
