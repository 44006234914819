import axios from "axios";

export const requestSaveCitizenship = (citizenship) =>
  axios.request({
    method: "post",
    url: "/api/route?path=citizen/citizenshipType",
    data: {
      citizenship,
    },
  });

export const requestCitizenship = () =>
  axios.request({
    method: "get",
    url: "/api/route?path=citizen/getCitizenShip",
  });

export const requestUserIdentifierForSegments = () =>
  axios.request({
    method: "get",
    url: "/api/route?path=auth/userIdentifierForSegments",
  });
