import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import {
  fetchLoanAndDisbursementDetails,
  updateFeesData,
  updateLoanAndDisbursementDetails,
} from "components/EducationLoan/Disbursements/redux/DisbursementsSlice";
import {
  requestLoanAndDisbursementDetails,
  requestProcessingFeesData,
} from "components/EducationLoan/Disbursements/redux/DisbursementsApi";

function* handleFetchLoanAndDisbursementDetails(action) {
  try {
    const feesDataResponse = yield call(
      requestProcessingFeesData,
      action.payload
    );
    if (feesDataResponse?.data?.success) {
      const loanAndDisbursementDetailsResponse = yield call(
        requestLoanAndDisbursementDetails,
        action.payload
      );
      if (loanAndDisbursementDetailsResponse?.data?.success) {
        yield put(
          updateLoanAndDisbursementDetails(
            loanAndDisbursementDetailsResponse?.data?.data
          )
        );
        yield put(updateFeesData(feesDataResponse?.data?.data));
      } else {
        toast.error(errorMessages.SOMETHING_WENT_WRONG);
      }
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  }
}

function* DisbursementsSaga() {
  yield takeLatest(
    fetchLoanAndDisbursementDetails,
    handleFetchLoanAndDisbursementDetails
  );
}

export default DisbursementsSaga;
