export function isValidDate(year, month, day) {
  const dateEnteredByStudent = new Date(year, month - 1, day);
  if (
    dateEnteredByStudent.getFullYear() === year &&
    year.toString().length === 4 &&
    dateEnteredByStudent.getMonth() === month - 1 &&
    dateEnteredByStudent.getDate() === day
  ) {
    return true;
  }
  return false;
}

export function isFutureDate(year, month, day) {
  const presentDate = new Date();
  const currentYear = presentDate.getFullYear();
  const currentMonth = presentDate.getMonth() + 1;
  const currentDate = presentDate.getDate();
  if (
    year > currentYear ||
    (year === currentYear && month > currentMonth) ||
    (year === currentYear && month === currentMonth && day > currentDate)
  ) {
    return true;
  }
  return false;
}
