import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { AuthToken } from "./auth_token";
import { getLeapCookie } from "./utils/leap-cookies";

export async function fetchUser() {
  if (typeof window !== "undefined" && window.User) {
    return window.User;
  }

  try {
    const res = await axios.get("/api/me");
    const json = res.data;

    const auth = new AuthToken(getLeapCookie(null, "token"));

    if (typeof window !== "undefined") {
      window.User = { ...json, auth: cloneDeep(auth) };
    }
    return { ...json, auth: cloneDeep(auth) };
  } catch (error) {
    if (typeof window !== "undefined") {
      delete window.__user;
    }
    return null;
  }
}

export function useFetchUser() {
  const [loading, setLoading] = useState(
    () => !(typeof window !== "undefined" && window.User)
  );
  const [user, setUser] = useState(() => {
    if (typeof window === "undefined") {
      return null;
    }

    return window.User || null;
  });

  useEffect(() => {
    if (!loading && user) {
      return;
    }
    setLoading(true);
    let isMounted = true;

    fetchUser().then((user) => {
      // Only set the user if the component is still mounted
      if (isMounted) {
        setUser(user);
        setLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return { user, loading };
}
