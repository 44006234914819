import TestScoresSection from "components/Form/ProfessionalDetailsForm/TestScoresSection";
import EducationDetailsSection from "components/Form/ProfessionalDetailsForm/EducationDetailsSection.jsx";
import WorkExperienceSection from "components/Form/ProfessionalDetailsForm/WorkExperienceSection";
import CourseDetailsSection from "components/Form/ProfessionalDetailsForm/CourseDetailsSection";
import SavingsSection from "components/Form/ProfessionalDetailsForm/SavingsSection";
import { highestEducationQualificationTypes } from "components/EducationLoan/constants";

const { UG, PG } = highestEducationQualificationTypes;

export const mutateInitialValues = (values) => {
  const {
    profile: {
      aptitudeTest,
      englishTest: languageTest,
      testScores,
      educations,
      totalExperience: totalWorkExperienceInMonths,
      employments,
      highestQualification,
      currentAnnualSalary,
      currentSavings,
    } = {},
    course,
    duration: durationInMonths,
    season,
    scholarships,
  } = values || {};
  const testScoreDetails = testScores?.reduce(
    (a, c) => {
      if (c.test === `${aptitudeTest}QUANT`) {
        a.aptitudeQuantitativeScore = c.score;
      } else if (c.test === `${aptitudeTest}VERBAL`) {
        a.aptitudeVerbalScore = c.score;
      } else if (c.test === languageTest) {
        a.languageTestScore = c.score;
      }
      return a;
    },
    {
      aptitudeTest,
      languageTest,
    }
  );
  const educationDetails = educations?.reduce((a, c) => {
    if (c.course === "X") {
      a.class10Percentage = c.gpa;
    } else if (c.course === "XII") {
      a.class12Percentage = c.gpa;
    } else if (c.course === "UG") {
      a.ugGraduationDetails = { ...c };
    } else if (c.course === "PG") {
      a.pgGraduationDetails = { ...c };
    }
    return a;
  }, {});
  const currentEmployment = {
    totalWorkExperienceInMonths,
    currentAnnualSalary,
    ...(employments[0] || {}),
  };
  const courseDetails = {
    ...course,
    durationInMonths,
    season,
  };
  const highestQualificationType =
    highestQualification === highestEducationQualificationTypes.DIPLOMA
      ? highestEducationQualificationTypes.UG
      : highestQualification;
  return {
    ...values,
    ...educationDetails,
    currentSavings,
    testScoreDetails,
    scholarshipAmount: scholarships[0]?.amount,
    highestQualification: highestQualificationType,
    currentEmployment,
    courseDetails,
  };
};

export const generateAccordionSectionDetails = () => [
  {
    title: "Test Scores",
    DisplayComponent: TestScoresSection,
    open: true,
  },
  {
    title: "Education",
    DisplayComponent: EducationDetailsSection,
    open: false,
  },
  {
    title: "Work Experience",
    DisplayComponent: WorkExperienceSection,
    open: false,
  },
  { title: "Savings", DisplayComponent: SavingsSection, open: false },
  {
    title: "Course Details",
    DisplayComponent: CourseDetailsSection,
    open: false,
  },
];

export const validateGPAScore = (gpaBase, gpaScore) => {
  if (!gpaScore && gpaScore !== 0) return "This field is required";
  if (!gpaBase) return "Please choose the appropriate GPA scale first";
  if (gpaScore > gpaBase) return `GPA cannot be greater than ${gpaBase}`;
  if (Number(gpaScore) < 0) return "Please enter a valid value";
};

export const professionalDetailsValidator = (values) => {
  const errors = {};
  if ([UG, PG].includes(values?.highestQualification)) {
    errors.ugGraduationDetails = {
      gpa: validateGPAScore(
        values?.ugGraduationDetails?.gpaBase,
        values?.ugGraduationDetails?.gpa
      ),
    };
  }
  if (values?.highestQualification === PG) {
    errors.pgGraduationDetails = {
      gpa: validateGPAScore(
        values?.pgGraduationDetails?.gpaBase,
        values?.pgGraduationDetails?.gpa
      ),
    };
  }
  return errors;
};
