import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchApplicationDetails,
  fetchBankAccountDetails,
  fetchNextStageInHistory,
  fetchRelationshipManagerDetails,
  resumeInactiveApplication,
  updateApplication,
  updateBankAccountDetails,
  updateIsLoading,
  updateNextStageInHistory,
  updateRelationshipManagerDetails,
} from "components/Application/redux/ApplicationSlice";
import {
  requestApplicationDetails,
  requestBankAccountDetails,
  requestNextStageInHistory,
  requestRelationshipManagerDetails,
  requestResumeInactiveApplication,
} from "components/Application/redux/ApplicationApi";
import { BankApplicationStages } from "constant/bankApplicationStages";
import Router from "next/router";
import { inschoolStages } from "constant/inschoolStages";
import { errorMessages } from "lib/utils/constants";
import { toast } from "react-toastify";

function* handleFetchApplicationDetails(action) {
  try {
    yield put(updateIsLoading(true));
    const res = yield call(requestApplicationDetails, action.payload);
    yield put(updateApplication(res?.data?.application));
  } catch (err) {
    console.error(err);
  } finally {
    yield put(updateIsLoading(false));
  }
}

function* handleFetchNextStageInHistory(action) {
  try {
    const res = yield call(requestNextStageInHistory, action.payload);
    if (res?.data?.success) {
      yield put(updateNextStageInHistory(res?.data?.data?.toLowerCase()));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleFetchBankAccountDetails(action) {
  try {
    const res = yield call(requestBankAccountDetails, action.payload);
    if (res?.data?.success) {
      const bankDetails = res?.data?.data;
      yield put(
        updateBankAccountDetails({
          ...bankDetails,
          showBankBanner:
            bankDetails?.stage !== BankApplicationStages.BA_ACCOUNT_OPENED,
        })
      );
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleFetchRelationshipManagerDetails(action) {
  try {
    const res = yield call(requestRelationshipManagerDetails, action.payload);
    if (res?.data?.success) {
      yield put(updateRelationshipManagerDetails(res?.data?.data));
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleResumeInactiveApplication(action) {
  try {
    yield put(updateIsLoading(true));
    const slug = action.payload;
    const res = yield call(requestResumeInactiveApplication, slug);
    if (res?.data?.success) {
      Router.push(
        `/applications/${slug}/${inschoolStages.OFFER_CONFIRMATION.toLowerCase()}`
      );
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
    console.error(err);
  } finally {
    yield put(updateIsLoading(false));
  }
}

function* applicationSaga() {
  yield takeLatest(fetchApplicationDetails, handleFetchApplicationDetails);
  yield takeLatest(fetchNextStageInHistory, handleFetchNextStageInHistory);
  yield takeLatest(fetchBankAccountDetails, handleFetchBankAccountDetails);
  yield takeLatest(
    fetchRelationshipManagerDetails,
    handleFetchRelationshipManagerDetails
  );
  yield takeLatest(resumeInactiveApplication, handleResumeInactiveApplication);
}

export default applicationSaga;
