import { referenceTypes } from "components/EducationLoan/constants";
import { Legend, Section } from "components/Form/sections";
import { generateReferenceFields } from "components/Form/AddressDetails/referenceDetailsFields";
import ReferenceDetailsFieldsSkeleton from "components/Form/AddressDetails/ReferenceDetailsFieldsSkeleton";

const ReferencesSection = ({ values }) => (
  <Section>
    <p className="text-xs text-gray-200 mb-4">
      Please give references of 2 people who we can contact in case we can't
      contact you
    </p>
    <div className="mb-10 pb-10 border-b">
      <Legend>Reference 1</Legend>
      <ReferenceDetailsFieldsSkeleton
        fields={generateReferenceFields(1)}
        referrer={referenceTypes.REFERENCE1}
        values={values}
      />
    </div>
    <div className="mb-3">
      <Legend>Reference 2</Legend>
      <ReferenceDetailsFieldsSkeleton
        fields={generateReferenceFields(2)}
        referrer={referenceTypes.REFERENCE2}
        values={values}
      />
    </div>
  </Section>
);

export default ReferencesSection;
