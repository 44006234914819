import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSaving: false,
  showPopupExperianError: false,
};

export const saveBureauMismatchDetails = createAction(
  "bureauDetails/saveBureauMismatchDetails"
);

const bureauDetailsSlice = createSlice({
  name: "bureauDetails",
  initialState,
  reducers: {
    updateIsSaving(state, action) {
      return { ...state, isSaving: action.payload };
    },
    updateShowPopupExperianError(state, action) {
      return { ...state, showPopupExperianError: action.payload };
    },
  },
});

export const {
  updateIsSaving,
  updateShowPopupExperianError,
} = bureauDetailsSlice.actions;
export default bureauDetailsSlice.reducer;
