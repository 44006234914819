import { Field } from "react-final-form";
import { Fieldset, Legend } from "components/Form/sections";
import GpaBase from "components/Form/ProfessionalDetailsForm/GPABaseField";

const HighestEducationFieldsSkeleton = ({
  title,
  gpaLevel,
  gpaLabel,
  fields,
  hasSeparator,
}) => (
  <Fieldset hasSeparator={hasSeparator}>
    <Legend>{title}</Legend>
    <div className="mb-6 md:mb-8">
      <GpaBase
        level={gpaLevel}
        label={gpaLabel}
      />
    </div>
    <div className="md:mb-8 md:flex md:-mx-2">
      <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
        <Field {...fields?.collegeName} />
      </div>
      <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
        <Field {...fields?.yearOfGraduation} />
      </div>
    </div>
    <div className="md:flex md:-mx-2">
      <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
        <Field {...fields?.backlog} />
      </div>
      <div className="md:w-1/2 md:px-2">
        <Field {...fields?.gapYear} />
      </div>
    </div>
  </Fieldset>
);

export default HighestEducationFieldsSkeleton;
