import axios from "axios";
import isArray from "lodash/isArray";
import kebabCase from "lodash/kebabCase";

const API_BASE = "/api/route?path=in-school/application";

export default class ApplicationService {
  constructor() {}

  async update(data, stage) {
    let response = await axios.post(
      `${API_BASE}/${kebabCase(stage?.toLowerCase())}`,
      data
    );
    return response.data;
  }

  async fetchAll() {
    return await axios.get(`${API_BASE}/fetch-all-applications`);
  }

  async fetchApplicantLocation() {
    return await axios.get(`${API_BASE}/applicant-location`);
  }

  async fetchUSApplication() {
    return await axios.get(`/api/route?path=us_application`);
  }

  async fetchCampus(slug) {
    return await axios.get(`/api/route?path=application/campus/${slug}`);
  }

  async submitUSApplication(data) {
    return await axios.post(`/api/route?path=us_application`, data);
  }

  async upload(file, slug, docType) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("slug", slug);
    formData.append("docType", docType);
    const response = await axios.post(`/api/uploadextradoc`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
}

export function getDocuments(documents) {
  return documents?.reduce((a, c) => {
    if (isArray(a[c.keyName])) {
      a[c.keyName].push(c);
    } else {
      a[c.keyName] = [c];
    }

    return a;
  }, {});
}
