import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentModal: false,
  processingPayment: false,
  orderId: "",
  clientSecret: "",
  feesData: null,
  isPaymentFailed: false,
  paymentFailureReason: {},
  application: null,
  isCreatingOrder: false,
  isPageLoading: true,
  nextStage: "",
  previouslyOfferedLoanAmount: null,
};

export const fetchFeeElements = createAction(
  "extraChargeCollectionDetails/fetchFeeElements"
);
export const fetchOutstandingFees = createAction(
  "extraChargeCollectionDetails/fetchOutstandingFees"
);
export const createOrder = createAction(
  "extraChargeCollectionDetails/createOrder"
);

const ExtraChargeCollectionDetails = createSlice({
  name: "extraChargeCollectionDetails",
  initialState,
  reducers: {
    updatePaymentModal(state, action) {
      return { ...state, paymentModal: action.payload };
    },
    updateProcessingPayment(state, action) {
      return { ...state, processingPayment: action.payload };
    },
    updateOrderId(state, action) {
      return { ...state, orderId: action.payload };
    },
    updateClientSecret(state, action) {
      return { ...state, clientSecret: action.payload };
    },
    updateFeesData(state, action) {
      return { ...state, feesData: action.payload };
    },
    updateIsPaymentFailed(state, action) {
      return { ...state, isPaymentFailed: action.payload };
    },
    updatePaymentFailureReason(state, action) {
      return { ...state, paymentFailureReason: action.payload };
    },
    updateApplication(state, action) {
      return { ...state, application: action.payload };
    },
    updateIsCreatingOrder(state, action) {
      return { ...state, isCreatingOrder: action.payload };
    },
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
    updateNextStage(state, action) {
      return { ...state, nextStage: action.payload };
    },
    updatePreviouslyOfferedLoanAmount(state, action) {
      return { ...state, previouslyOfferedLoanAmount: action.payload };
    },
  },
});

export const extraChargeCollectionActions = {
  ...ExtraChargeCollectionDetails.actions,
};
export default ExtraChargeCollectionDetails.reducer;
