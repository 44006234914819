import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchApplicantInfo,
  updateLoadingToggle,
  updateVisaRejectedFlag,
} from "./ApplicationDeclinedSlice";
import { requestApplicantData } from "./ApplicationDeclinedApi";
import { ValidUSVisaTypes } from "../../../../lib/utils/constants";

function* handleFetchApplicantInfo(action) {
  yield put(updateLoadingToggle(true));
  try {
    const res = yield call(requestApplicantData, action.payload);
    const visaType = res?.data?.bankApplication?.applicant?.visaType;
    const visaUploaded = res?.data?.bankApplication?.applicant?.visaUploaded;
    if (!visaUploaded) {
      if (visaType && visaType !== ValidUSVisaTypes.F1) {
        yield put(updateVisaRejectedFlag(true));
      } else {
        yield put(updateVisaRejectedFlag(false));
      }
    } else {
      yield put(updateVisaRejectedFlag(false));
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(updateLoadingToggle(false));
  }
}

function* applicationDeclinedSaga() {
  yield takeLatest(fetchApplicantInfo, handleFetchApplicantInfo);
}

export default applicationDeclinedSaga;
