import axios from "axios";

const baseApi = "/api/route?path=bank-account-details";

export const requestCaptureBankDetails = (data) =>
  axios.request({
    method: "post",
    url: baseApi + "/capture",
    data,
  });

export const requestValidateUser = () =>
  axios.request({
    method: "get",
    url: baseApi + "/validate-user",
  });
