import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialValues: {},
  application: null,
  isSaving: false,
  isPageLoading: false,
};

export const fetchDocumentsUpload = createAction(
  "documentsUpload/fetchDocumentsUpload"
);

export const saveDocumentsUpload = createAction(
  "documentsUpload/saveDocumentsUpload"
);

const documentsUploadSlice = createSlice({
  name: "documentsUpload",
  initialState,
  reducers: {
    updateInitialValues(state, action) {
      return { ...state, initialValues: action.payload };
    },
    updateApplication(state, action) {
      return { ...state, application: action.payload };
    },
    updateIsSaving(state, action) {
      return { ...state, isSaving: action.payload };
    },
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
  },
});

export const {
  updateInitialValues,
  updateApplication,
  updateIsSaving,
  updateIsPageLoading,
} = documentsUploadSlice.actions;
export default documentsUploadSlice.reducer;
