import { Field } from "react-final-form";
import { relationshipTypes } from "components/EducationLoan/constants";

const ReferenceDetailsFieldsSkeleton = ({ fields, referrer, values }) => {
  const isOtherRelationshipSelected =
    values[referrer]?.relationship === relationshipTypes.OTHER;
  const customCss = isOtherRelationshipSelected ? "md:w-1/2" : "md:w-full";
  return (
    <>
      <div className="md:mb-8 md:flex md:-mx-2">
        <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
          <Field {...fields?.referenceName} />
        </div>
        <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
          <Field {...fields?.referencePhone} />
        </div>
      </div>
      <div className="md:mb-8 md:flex md:-mx-2">
        <div className={`mb-6 md:mb-0 md:px-2 ${customCss}`}>
          <Field {...fields?.referenceRelationship} />
        </div>
        {isOtherRelationshipSelected && (
          <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
            <Field {...fields?.referenceRelationshipInDetail} />
          </div>
        )}
      </div>
    </>
  );
};

export default ReferenceDetailsFieldsSkeleton;
