import { Field } from "react-final-form";
import {
  aptitudeTestOptions,
  englishTestOptions,
  yesOrNoOptions,
} from "components/EducationLoan/SelectOptions";
import { MultiSelect } from "components/Inputs/MultiSelect";
import { Switch } from "components/Inputs/Switch";
import { Input } from "components/Inputs/TextField";
import { Section } from "components/Form/sections";
import AptitudeTestFieldsSkeleton from "components/Form/ProfessionalDetailsForm/AptituteTestFieldsSkeleton";
import {
  generateAptitudeTestFields,
  generateEnglishTestField,
} from "components/Form/ProfessionalDetailsForm/fieldsGenerator";

const TestScoresSection = ({ values }) => (
  <Section topPadding>
    <div>
      <div className="md:flex md:mb-8 md:-mx-2">
        <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
          <Field
            name="testScoreDetails.aptitudeTest"
            component={MultiSelect}
            label="Select an academic test"
            options={aptitudeTestOptions}
            isSearchable
          />
        </div>
      </div>
      {values?.testScoreDetails?.aptitudeTest &&
        values?.testScoreDetails?.aptitudeTest !== "WAIVED_OFF" && (
          <AptitudeTestFieldsSkeleton
            fields={generateAptitudeTestFields(
              values?.testScoreDetails?.aptitudeTest
            )}
          />
        )}
      <div className="md:flex md:-mx-2 md:mb-8">
        <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
          <Field
            name="testScoreDetails.languageTest"
            component={MultiSelect}
            label="Select an English test"
            options={englishTestOptions}
            isSearchable
          />
        </div>
      </div>
      <div className="md:flex md:-mx-2 md:mb-8">
        <div className="mb-6 md:mb-0 md:w-1/2 md:px-2">
          <Field
            {...generateEnglishTestField(
              values?.testScoreDetails?.languageTest
            )}
          />
        </div>
      </div>
    </div>
    <div className="md:-mx-2 whitespace-nowrap">
      <div
        className={`md:w-1/3 md:px-2 ${values?.hasScholarships ? "mb-4" : ""}`}
      >
        <span>Do you have scholarships?</span>
        <Field
          name="hasScholarships"
          component={Switch}
          options={yesOrNoOptions}
        />
      </div>
      {values?.hasScholarships && (
        <div className="md:w-3/5 md:px-2">
          <Field
            name="scholarshipAmount"
            label="Scholarship Amount in INR"
            component={Input}
            type="number"
            validate={(value) => {
              if (!value && value !== 0)
                return "Scholarship amount is required";
              if (value.toString().includes(".")) return "Decimal not allowed";
              if (value < 0 || value > 10000000)
                return "Please enter a valid amount";
            }}
            placeholder="Enter the scholarship amount"
            helperText="Please add the total amount of scholarships that are confirmed for this course only"
          />
        </div>
      )}
    </div>
  </Section>
);

export default TestScoresSection;
