import apolloClient from "apolloClient";
import { gql } from "@apollo/client";
import axios from "axios";

export const requestExtraCosignerDetails = (slug) =>
  apolloClient.query({
    query: gql`
      query($slug: String!) {
        application(slug: $slug) {
          id
          slug
          cosignerAttempt
          cosigner3 {
            id
            firstName
            middleName
            lastName
            relationship
            phone
            isPhoneVerified
            consent
            email
            dob
            gender
            fathersName
            noOfDependents: dependents
            employmentStatus
            totalWorkExperience
            dateOfRetirement
            currentEmployer: employer
            monthlySalary: monthlyNetTakeHome
            monthlyObligation
            companyVintage
            companyName: company
            lastYearsProfitAfterTax: pat
            typeOfResidence
            residentialProof
            pan
            creditScore
            secondaryEmail
            secondaryPhone
            employerAddress {
              line1
              line2
              city
              state
              pincode
            }
            currentAddress {
              line1
              line2
              city
              state
              pincode
            }
            permanentAddress {
              line1
              line2
              pincode
              state
              city
              country
            }
            experianStatus
            experianResponse {
              status
              code
              error
              maskedPhone
            }
            documents {
              id
              name
              keyName
              size
              url
              password
            }
          }
          profile {
            fathersTitle
            mothersTitle
            fathersName
            mothersName
            currentAnnualSalary
            testScores {
              test
              score
            }
            educations {
              college
              year
              gpa
              gpaBase
              backLogCount: backlogCount
              gapYearCount
              course
            }
            highestQualification
            aptitudeTest
            englishTest
            currentSavings
            totalExperience
            employments {
              employer
              designation
            }
          }
          offer {
            loanInterest
            loanAmount
            loanAmountInUSD
            loanTenure
            repaymentType
          }
          duration
          course {
            courseId: id
          }
          durationInMonths: duration
          season
          applicationState {
            screen
            status
            underwriting
            subStage
          }
        }
      }
    `,
    variables: { slug },
    fetchPolicy: "no-cache",
  });

export const requestCaptureExtraCosignerDetails = (data) =>
  axios.post(
    "/api/route?path=in-school/application/extra-cosigner-details",
    data
  );

export const requestCaptureExtraCosignerInHeldByCredit = (data) =>
  axios.post("/api/route?path=in-school/application/held-by-credit", data);
