import { Field } from "react-final-form";

const AptitudeTestFieldsSkeleton = ({ fields }) => (
  <div className="md:flex md:mb-8 md:-mx-2">
    <div className="mb-6 md:mb-0 md:px-2 md:w-full">
      <Field {...fields?.quantScoreField} />
    </div>
    <div className="mb-6 md:mb-0 md:px-2 md:w-full">
      <Field {...fields?.verbalScoreField} />
    </div>
  </div>
);

export default AptitudeTestFieldsSkeleton;
