import { Field } from "react-final-form";
import { addressFieldsGeneratorTypes } from "components/EducationLoan/constants";
import { TinySwitch } from "components/Inputs/Switch";
import { Legend, Section } from "components/Form/sections";
import { generateAddressFields } from "components/Form/shared/addressDetailsFields";
import AddressDetailsFieldsSkeleton from "components/Form/shared/AddressDetailsFieldsSkeleton";

const CosignerAddressSection = ({ values }) => (
  <Section>
    <div className="mb-10 pb-10 border-b">
      <Legend>
        {values?.cosigner?.firstName || "Cosigner"}
        's Current Address
      </Legend>
      <AddressDetailsFieldsSkeleton
        fields={generateAddressFields(
          addressFieldsGeneratorTypes.COSIGNER_CURRENT_ADDRESS
        )}
      />
    </div>
    <div>
      <div className="mb-4 md:flex justify-between items-center">
        <Legend last>
          {values?.cosigner?.firstName || "Applicant"}
          's Permanent Address
        </Legend>
        <Field
          name="isCosignerAddressSame"
          type="checkbox"
          component={TinySwitch}
          label="Same as current address"
          handleSwitchClick={() => void 0}
        />
      </div>
      {!values?.isCosignerAddressSame && (
        <AddressDetailsFieldsSkeleton
          fields={generateAddressFields(
            addressFieldsGeneratorTypes.COSIGNER_PERMANENT_ADDRESS
          )}
        />
      )}
    </div>
  </Section>
);

export default CosignerAddressSection;
