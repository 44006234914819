import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import {
  captureBankDetails,
  updateApplicationSlug,
  updateIsPageLoading,
  updateIsSavingForm,
  updateScreen,
  validateUser,
} from "components/UpdateBankDetails/redux/BankDetailsUpdateSlice";
import {
  requestCaptureBankDetails,
  requestValidateUser,
} from "components/UpdateBankDetails/redux/BankDetailsUpdateApi";
import {
  bankUpdateScreens,
  mutateValues,
} from "components/UpdateBankDetails/redux/BankDetailsUpdateHelper";

function* handleValidateUser() {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestValidateUser);
    if (res?.data?.success) {
      yield put(updateApplicationSlug(res?.data?.data));
      yield put(updateScreen(bankUpdateScreens.UPDATE_FORM));
    } else {
      const bankUpdateStatus = res?.data?.data;
      if (!bankUpdateStatus) {
        toast.error(errorMessages.SOMETHING_WENT_WRONG);
        console.error(res?.data?.message);
      } else yield put(updateScreen(bankUpdateStatus));
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsPageLoading(false));
  }
}

function* handleCaptureBankDetails(action) {
  try {
    yield put(updateIsSavingForm(true));
    const res = yield call(
      requestCaptureBankDetails,
      mutateValues(action.payload)
    );
    if (res?.data?.success) {
      yield put(updateScreen(bankUpdateScreens.UPDATE_SUCCESS));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsSavingForm(false));
  }
}

function* bankDetailsUpdateSaga() {
  yield takeLatest(validateUser, handleValidateUser);
  yield takeLatest(captureBankDetails, handleCaptureBankDetails);
}

export default bankDetailsUpdateSaga;
