import { Field } from "react-final-form";
import { currentSavingsOptions } from "components/EducationLoan/SelectOptions";
import { MultiSelect } from "components/Inputs/MultiSelect";
import { Legend, Section } from "components/Form/sections";

const SavingsSection = () => (
  <Section paddingTop>
    <Legend>How much savings do you have for education?</Legend>
    <div className="md:flex">
      <div className="md:w-1/2 md:pr-2">
        <div className="inline-block text-xs text-gray-700 my-1">
          Mention savings that you plan to use for your study
        </div>
        <Field
          name="currentSavings"
          label="Current Savings in INR"
          placeholder="Select an amount"
          component={MultiSelect}
          options={currentSavingsOptions}
          validate={(value) => {
            if (!value) return "This field is required";
          }}
        />
      </div>
    </div>
  </Section>
);

export default SavingsSection;
