import apolloClient from "apolloClient";
import { gql } from "@apollo/client";
import { inschoolStages } from "constant/inschoolStages";
import ApplicationService from "services/applicationService";

export const requestCosignerDetails = (slug) =>
  apolloClient.query({
    query: gql`
      query($slug: String!) {
        application(slug: $slug) {
          id
          slug
          cosignerAttempt
          cosigner1 {
            id
            firstName
            middleName
            lastName
            relationship
            phone
            isPhoneVerified
            consent
            email
            dob
            gender
            fathersName
            noOfDependents: dependents
            employmentStatus
            totalWorkExperience
            dateOfRetirement
            currentEmployer: employer
            monthlySalary: monthlyNetTakeHome
            monthlyObligation
            otherRelationshipInDetail
            companyVintage
            companyName: company
            lastYearsProfitAfterTax: pat
            typeOfResidence
            residentialProof
            pan
            creditScore
            secondaryEmail
            secondaryPhone
            employerAddress {
              line1
              line2
              city
              state
              employerPincode: pincode
            }
            currentAddress {
              pincode
            }
            experianStatus
            experianResponse {
              status
              code
              error
              maskedPhone
            }
          }
          cosigner2 {
            id
            firstName
            middleName
            lastName
            relationship
            phone
            isPhoneVerified
            consent
            email
            dob
            gender
            fathersName
            noOfDependents: dependents
            employmentStatus
            totalWorkExperience
            otherRelationshipInDetail
            dateOfRetirement
            currentEmployer: employer
            monthlySalary: monthlyNetTakeHome
            monthlyObligation
            companyVintage
            companyName: company
            lastYearsProfitAfterTax: pat
            typeOfResidence
            residentialProof
            pan
            creditScore
            secondaryEmail
            secondaryPhone
            employerAddress {
              line1
              line2
              city
              state
              employerPincode: pincode
            }
            currentAddress {
              line1
              line2
              city
              state
              pincode
            }
            experianStatus
            experianResponse {
              status
              code
              error
              maskedPhone
            }
          }
          profile {
            fathersTitle
            mothersTitle
            currentAnnualSalary
            testScores {
              test
              score
            }
            educations {
              college
              year
              gpa
              gpaBase
              backLogCount: backlogCount
              gapYearCount
              course
            }
            highestQualification
            aptitudeTest
            englishTest
            currentSavings
            totalExperience
            employments {
              employer
              designation
            }
          }
          duration
          course {
            name
            courseId: id
            campus {
              college {
                name
              }
            }
          }
          offer {
            loanInterest
            loanAmount
            loanAmountInUSD
            loanTenure
            repaymentType
          }
          durationInMonths: duration
          season
          applicationState {
            screen
            status
            underwriting
            subStage
          }
        }
      }
    `,
    variables: { slug },
    fetchPolicy: "no-cache",
  });

export const requestSaveCosignerDetails = (data) => {
  const app = new ApplicationService(data?.slug);
  return app.update(data, inschoolStages.COSIGNER_DETAILS);
};
