export const isBrowserClient = () => typeof window !== "undefined";

export const isNumber = (value) => typeof value === "number" && !isNaN(+value);

export const isFalsy = (value) =>
  value === `` ||
  value === null ||
  value === undefined ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === `object` && Object.keys(value).length === 0);
