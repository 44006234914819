// # Speed in Seconds (spinner rotating speed)
// # Spinner Size in em units relative to the font size of the parent element.
// # borderSize in em units relative to the font size of the parent element.
// # borderColor color background for border.

const Spinner = ({
  speed = 0.8,
  size = 2.5,
  borderSize = 0.2,
  borderColor = "#ffffff",
  color = "#00b0aa",
}) => {
  return (
    <div className="loadingspinner">
      <style jsx>
        {`
          .loadingspinner {
            pointer-events: none;
            width: ${size}em;
            height: ${size}em;
            border: ${borderSize}em solid transparent;
            border-color: ${borderColor};
            border-top-color: ${color};
            border-radius: 50%;
            animation: loadingspin ${speed}s linear infinite;
          }

          @keyframes loadingspin {
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  );
};

export default Spinner;
