import axios from "axios";
import apolloClient from "../../../../apolloClient";
import { gql } from "@apollo/client";

export const requestPersonalInfo = (slug) => {
  return apolloClient.query({
    query: gql`
      query($slug: String) {
        bankApplication(slug: $slug) {
          id
          slug
          status
          experianStatus
          experianResponse {
            status
            bureauScore
            code
            maskedPhone
          }
          agreementAcceptedDate
          applicant {
            firstName
            lastName
            email
            pan
            creditCardConsent
          }
        }
      }
    `,
    variables: { slug },
  });
};

export const savePersonalInfo = (data) =>
  axios.request({
    method: "post",
    url: "/api/postBankAccountData?path=personalinfo",
    data,
  });

export const saveExperianMismatchInfo = (values) => {
  const payload = values?.secondaryPhone
    ? { slug: values?.slug, secondaryMobile: values.secondaryPhone }
    : {
        slug: values?.slug,
        documentUrl: values?.creditReportUpload?.creditReportUrl,
        documentName: "creditReport",
        creditScore: values?.creditReportUpload?.creditScore,
        creditReportPassword: values?.creditReportUpload?.creditReportPassword,
      };
  return axios.request({
    method: "post",
    url: values?.secondaryPhone
      ? "/api/postBankAccountData?path=secondary_phone_experian_fetch"
      : "/api/postBankAccountData?path=uploadCreditReport",
    data: payload,
  });
};

export const requestApplicantCreditReport = (slug) => {
  return apolloClient.query({
    query: gql`
      query($slug: String) {
        bankApplication(slug: $slug) {
          id
          slug
          applicant {
            documents {
              id
              documentName
              url
              status
            }
          }
        }
      }
    `,
    variables: { slug },
  });
};
