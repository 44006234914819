import { Field } from "react-final-form";
import { applicationStatusOptions } from "components/EducationLoan/SelectOptions";
import { MultiSelect } from "components/Inputs/MultiSelect";
import { Section } from "components/Form/sections";

const ApplicationStatusSection = ({ application }) => (
  <Section>
    <div className="md:flex md:-mx-2">
      <div
        className={`mb-6 md:mb-0 md:px-2 ${
          application?.season ? "w-full" : "md:w-1/2"
        }`}
      >
        <Field
          name="applicationStatus"
          component={MultiSelect}
          label="Application Status"
          placeholder="Select your Application Status"
          options={applicationStatusOptions}
          validate={(v) => {
            if (!v) return "Application status is a required field";
          }}
        />
      </div>
    </div>
  </Section>
);

export default ApplicationStatusSection;
