import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import { requestExtraCosignerDetails } from "components/Form/ExtraCosignerForm/redux/ExtraCosignerDetailsApi";
import { mutateExtraCosignerInitialValues } from "components/Form/ExtraCosignerForm/redux/extraCosignerFormHelper";
import {
  fetchExtraCosignerDetails,
  updateApplication,
  updateInitialValues,
} from "components/Form/ExtraCosignerForm/redux/ExtraCosignerDetailsSlice";

function* handleFetchExtraCosignerDetails(action) {
  try {
    const res = yield call(requestExtraCosignerDetails, action.payload);
    if (res.data) {
      yield put(
        updateInitialValues(
          mutateExtraCosignerInitialValues(res?.data?.application)
        )
      );
      yield put(updateApplication(res?.data?.application));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  }
}

function* extraCosignerDetailsSaga() {
  yield takeLatest(fetchExtraCosignerDetails, handleFetchExtraCosignerDetails);
}

export default extraCosignerDetailsSaga;
