import { cosignerResidenceTypes } from "components/EducationLoan/constants";
import { inschoolStages } from "constant/inschoolStages";
import { Pat } from "lib/DataMapper/enums";

export const mutateInitialValues = (values) => {
  const { cosignerAttempt, applicationState, cosigner1, cosigner2 } =
    values || {};

  const isScreenCosignerDetails =
    applicationState?.screen === inschoolStages.COSIGNER_DETAILS;

  const cosignerData =
    (isScreenCosignerDetails && cosignerAttempt == 0) ||
    (!isScreenCosignerDetails && cosignerAttempt <= 1)
      ? cosigner1
      : cosigner2;

  const {
    currentAddress,
    phone: phoneNumber,
    isPhoneVerified: isVerified,
    employerAddress,
    lastYearsProfitAfterTax,
  } = cosignerData || {};

  const { OWNED, RENTED } = cosignerResidenceTypes;
  const validResidenceType = [OWNED, RENTED].includes(
    cosignerData?.typeOfResidence
  );

  delete values["isPhoneVerified"];

  return {
    ...values,
    ...cosignerData,
    lastYearsProfitAfterTax: Pat[lastYearsProfitAfterTax] ?? null,
    typeOfResidence: validResidenceType ? cosignerData?.typeOfResidence : null,
    cosignerPincode: currentAddress?.pincode,
    phone: { phoneNumber, isVerified },
    ...employerAddress,
  };
};
