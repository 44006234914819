import { parentsTitles } from "components/EducationLoan/constants";
import { mutateValues as mutateDocumentsData } from "components/Form/DocumentsUpload/redux/DocumentsUploadHelper";
import { splitName } from "components/Form/ExtraCosignerForm/utilFunctions/extraCosignerNameSplitter";
import { inschoolStages } from "constant/inschoolStages";
import { Pat } from "lib/DataMapper/enums";
import { getDocuments } from "services/applicationService";

export const mutateExtraCosignerInitialValues = (application) => {
  const { cosigner3, profile, applicationState } = application;
  let initialValues;
  if (cosigner3 != null && cosigner3.firstName !== null) {
    initialValues = {
      ...cosigner3,
      currentAddressPinCode: cosigner3?.currentAddress?.pincode,
      lastYearsProfitAfterTax: Pat[cosigner3?.lastYearsProfitAfterTax] ?? null,
      phoneObj: {
        phoneNumber: cosigner3.phone || "",
        isVerified: cosigner3.isPhoneVerified,
      },
      documents: getDocuments(cosigner3.documents),
    };
  } else {
    if (applicationState?.screen === inschoolStages.HELD_BY_CREDIT) {
      initialValues = {
        phoneObj: { phoneNumber: "", isPhoneVerified: false },
      };
    } else {
      const { firstName, middleName, lastName } = splitName(
        profile.fathersName,
        profile.mothersName,
        profile.fathersTitle
      );
      initialValues = {
        phoneObj: { phoneNumber: "", isPhoneVerified: false },
        firstName,
        middleName,
        lastName,
        currentAddressPinCode: cosigner3?.currentAddress?.pincode,
        profile: {
          fathersTitle: profile?.fathersTitle,
          mothersTitle: profile?.mothersTitle,
        },
      };
    }
  }
  return initialValues;
};

export const mutateExtraCosignerSubmitValues = (values, application) => {
  const formdata = {
    ...values,
    ...mutateDocumentsData(values?.documents),
    slug: application.slug,
    phone: values?.phoneObj?.phoneNumber,
  };
  const middleName = formdata.middleName;
  if (!middleName) {
    formdata.middleName = null;
  }
  if (application.profile.fathersTitle === parentsTitles.MR) {
    formdata.gender = "M";
    formdata.relationship = "FATHER";
  } else {
    formdata.gender = "F";
    formdata.relationship = "MOTHER";
  }
  return formdata;
};
