import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchApplicantDocumentsForReupload,
  setApplicationData,
  updateNextStage,
  saveDocumentsReuploadInfo,
} from "./DocumentReuploadDetailsSlice";
import {
  requestApplicantDocumentsForReupload,
  requestDocumentsReupload,
} from "./DocumentReuploadDetailsApi";
import { BankAccountDocumentStatus } from "../../../../lib/utils/constants";
import { toast } from "react-toastify";

function* handleFetchApplicantDocumentsForReupload(action) {
  try {
    const res = yield call(
      requestApplicantDocumentsForReupload,
      action.payload
    );
    const rejectedDocuments = {};
    res?.data?.bankApplication?.applicant?.documents.forEach((doc) => {
      if (doc.status === BankAccountDocumentStatus.REJECTED) {
        rejectedDocuments[doc.documentName] = doc.id;
      }
    });
    const rejectedApplicant = {
      ...res?.data?.bankApplication?.applicant,
      rejectedDocuments,
      rejectionReason: res?.data?.bankApplication?.salesTeamComment,
      docs: {},
    };
    yield put(
      setApplicationData({
        applicant: rejectedApplicant,
      })
    );
  } catch (err) {
    console.error(err);
  }
}

function* handleSaveDocumentsReuploadInfo(action) {
  try {
    const res = yield call(requestDocumentsReupload, action.payload);
    if (!res?.data?.success) {
      console.error(res?.data?.message);
      toast.error(
        res?.data?.message ||
          "Unable to process your documents at the moment. Please try again later."
      );
      return;
    }
    const validationResult = res?.data?.data?.validationResult;
    if (validationResult) {
      const validationErrors = validationResult?.errors.map((err) => {
        return err.message;
      });
      const errMsg = validationErrors.join(" ");
      toast.error(errMsg);
    } else {
      yield put(updateNextStage(res?.data?.data?.nextStage));
    }
  } catch (err) {
    console.error(err);
    toast.error(
      "Something went wrong in saving your data. Please try again later."
    );
  }
}

function* documentReuploadDetailsSaga() {
  yield takeLatest(
    fetchApplicantDocumentsForReupload,
    handleFetchApplicantDocumentsForReupload
  );
  yield takeLatest(saveDocumentsReuploadInfo, handleSaveDocumentsReuploadInfo);
}

export default documentReuploadDetailsSaga;
