import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  application: null,
  isLoading: true,
  nextStageInHistory: "",
  bankAccountDetails: null,
  relationshipManagerDetails: null,
};

export const fetchApplicationDetails = createAction(
  "application/fetchApplicationDetails"
);

export const fetchNextStageInHistory = createAction(
  "application/fetchNextStageInHistory"
);

export const fetchBankAccountDetails = createAction(
  "application/fetchBankAccountDetails"
);

export const fetchRelationshipManagerDetails = createAction(
  "application/fetchRelationshipManagerDetails"
);

export const resumeInactiveApplication = createAction(
  "application/resumeInactiveApplication"
);

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    updateApplication(state, action) {
      return { ...state, application: action.payload };
    },
    updateIsLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    updateNextStageInHistory(state, action) {
      return { ...state, nextStageInHistory: action.payload };
    },
    updateBankAccountDetails(state, action) {
      return { ...state, bankAccountDetails: action.payload };
    },
    updateRelationshipManagerDetails(state, action) {
      return { ...state, relationshipManagerDetails: action.payload };
    },
  },
});

export const {
  updateApplication,
  updateIsLoading,
  updateNextStageInHistory,
  updateBankAccountDetails,
  updateRelationshipManagerDetails,
} = applicationSlice.actions;
export default applicationSlice.reducer;
