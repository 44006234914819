import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialValues: {},
  application: null,
};

export const fetchExtraCosignerDetails = createAction(
  "extraCosignerDetails/fetchExtraCosignerDetails"
);

const extraCosignerDetailsSlice = createSlice({
  name: "extraCosignerDetails",
  initialState,
  reducers: {
    updateInitialValues(state, action) {
      return { ...state, initialValues: action.payload };
    },
    updateApplication(state, action) {
      return { ...state, application: action.payload };
    },
  },
});

export const {
  updateInitialValues,
  updateApplication,
} = extraCosignerDetailsSlice.actions;
export default extraCosignerDetailsSlice.reducer;
