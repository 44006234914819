import apolloClient from "apolloClient";
import { gql } from "@apollo/client";
import ApplicationService from "services/applicationService";
import { inschoolStages } from "constant/inschoolStages";

export const requestMonthlyPaymentDetails = (slug) =>
  apolloClient.query({
    query: gql`
      query($slug: String!) {
        application(slug: $slug) {
          id
          slug
          duration
          minimumMonthlyPayments
          mmpDuration: minimumMonthlyPaymentDuration
          country
          cosignerAttempt
          course {
            name
            campus {
              college {
                name
              }
            }
          }
          profile {
            experianStatus
            experianResponse {
              status
              code
              error
              maskedPhone
            }
          }
          cosigner1 {
            experianStatus
            experianResponse {
              status
              code
              error
              maskedPhone
            }
          }
          cosigner2 {
            experianStatus
            experianResponse {
              status
              code
              error
              maskedPhone
            }
          }
          offer {
            loanInterest
            loanAmount
            loanAmountInUSD
            loanTenure
            repaymentType
          }
          applicationState {
            screen
            status
            underwriting
            subStage
          }
        }
      }
    `,
    variables: { slug },
    fetchPolicy: "no-cache",
  });

export const requestSaveMonthlyPaymentDetails = (data) => {
  const app = new ApplicationService(data?.slug);
  return app.update(data, inschoolStages.PAYMENT_DETAILS);
};
