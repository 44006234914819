import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  fetchFeeElements,
  moveStageToPostSanctionDocPending,
  updateApplication,
  updateIsPageLoading,
  updateNextStage,
} from "components/EducationLoan/PFPaid/redux/PFPaidSlice";
import { errorMessages } from "lib/utils/constants";
import { requestFeeElements } from "components/EducationLoan/SanctionGenerated/redux/SanctionGeneratedApi";
import { requestMoveToPostSanctionDocPending } from "components/EducationLoan/PFPaid/redux/PFPaidApi";

function* handleFetchFeeElements(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestFeeElements, action.payload);
    if (res.data) {
      yield put(updateApplication(res?.data?.application));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsPageLoading(false));
  }
}

function* handleStageChangeToPostSanctionDocPending(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestMoveToPostSanctionDocPending, action.payload);
    if (res?.data?.success) {
      yield put(updateNextStage(res?.data?.data?.toLowerCase()));
    } else {
      yield put(updateIsPageLoading(false));
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    yield put(updateIsPageLoading(false));
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  }
}

function* pfPaidDetailsSaga() {
  yield takeLatest(fetchFeeElements, handleFetchFeeElements);
  yield takeLatest(
    moveStageToPostSanctionDocPending,
    handleStageChangeToPostSanctionDocPending
  );
}

export default pfPaidDetailsSaga;
