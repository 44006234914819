import axios from "axios";
import apolloClient from "../../../../apolloClient";
import { gql } from "@apollo/client";

export const requestApplicantDocumentsForReupload = (slug) => {
  return apolloClient.query({
    query: gql`
      query($slug: String) {
        bankApplication(slug: $slug) {
          id
          slug
          salesTeamComment
          applicant {
            visaType
            visaUploaded
            documents {
              id
              documentName
              url
              status
            }
          }
        }
      }
    `,
    variables: { slug },
  });
};

export const requestDocumentsReupload = (payload) => {
  return axios.request({
    method: "post",
    url: "/api/postBankAccountData?path=reUploadDocuments",
    data: payload,
  });
};
