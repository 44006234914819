import { call, put, select, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import {
  fetchMonthlyPaymentDetails,
  saveMonthlyPaymentDetails,
  updateInitialValues,
  updateIsPageLoading,
  updateIsSaving,
} from "components/Form/MonthlyPaymentForm/redux/MonthlyPaymentSlice";
import {
  requestMonthlyPaymentDetails,
  requestSaveMonthlyPaymentDetails,
} from "components/Form/MonthlyPaymentForm/redux/MonthlyPaymentApi";
import Router from "next/router";
import { triggerInschoolTrackForm } from "lib/utils/events_utils";
import { logEvent } from "components/Analytics/google-analytics";

const getStore = (store) => store?.monthlyPaymentDetails;

function* handleFetchMonthlyPaymentDetails(action) {
  try {
    yield put(updateIsPageLoading(true));
    const res = yield call(requestMonthlyPaymentDetails, action.payload);
    if (res.data) {
      yield put(updateInitialValues(res?.data?.application));
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsPageLoading(false));
  }
}

function* handleSaveMonthlyPaymentDetails(action) {
  try {
    yield put(updateIsSaving(true));
    const { slug, minimumMonthlyPayments } = action?.payload;
    if (!slug || !minimumMonthlyPayments) {
      throw new Error("Slug and minimumMonthlyPayments cannot be empty");
    }
    const res = yield call(requestSaveMonthlyPaymentDetails, action.payload);
    const { initialValues: application } = yield select(getStore);
    yield call(triggerInschoolTrackForm, {
      values: action.payload,
      applicationId: application?.id,
      screen: application?.applicationState?.screen,
      nextStage: res?.success && res?.data,
    });
    if (res.success) {
      yield call(
        logEvent,
        "Page Completed",
        `${application?.applicationState?.screen} completed`,
        `${application?.slug}`
      );
      Router.push(
        `/applications/${action.payload.slug}/${res?.data?.toLowerCase()}`
      );
    } else {
      toast.error(errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsSaving(false));
  }
}

function* monthlyPaymentDetailsSaga() {
  yield takeLatest(
    fetchMonthlyPaymentDetails,
    handleFetchMonthlyPaymentDetails
  );
  yield takeLatest(saveMonthlyPaymentDetails, handleSaveMonthlyPaymentDetails);
}

export default monthlyPaymentDetailsSaga;
