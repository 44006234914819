import axios from "axios";
import apolloClient from "../../../../apolloClient";
import { gql } from "@apollo/client";

export const requestApplicantDocuments = (slug) => {
  return apolloClient.query({
    query: gql`
      query($slug: String) {
        bankApplication(slug: $slug) {
          id
          slug
          applicant {
            visaType
            visaUploaded
            documents {
              id
              documentName
              url
              status
            }
          }
        }
      }
    `,
    variables: { slug },
  });
};

export const saveDocumentsData = (data) => {
  return axios.request({
    method: "post",
    url: "/api/postBankAccountData?path=documents",
    data,
  });
};
