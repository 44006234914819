import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialValues: {},
  isSaving: false,
  isPageLoading: false,
};

export const fetchMonthlyPaymentDetails = createAction(
  "monthlyPaymentDetails/fetchMonthlyPaymentDetails"
);

export const saveMonthlyPaymentDetails = createAction(
  "monthlyPaymentDetails/saveMonthlyPaymentDetails"
);

const monthlyPaymentDetailsSlice = createSlice({
  name: "monthlyPaymentDetails",
  initialState,
  reducers: {
    updateInitialValues(state, action) {
      return { ...state, initialValues: action.payload };
    },
    updateIsSaving(state, action) {
      return { ...state, isSaving: action.payload };
    },
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
  },
});

export const {
  updateInitialValues,
  updateIsSaving,
  updateIsPageLoading,
} = monthlyPaymentDetailsSlice.actions;
export default monthlyPaymentDetailsSlice.reducer;
