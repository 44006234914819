import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialValues: {},
  application: null,
  experianErrorResponse: null,
  isSaving: false,
  isPageLoading: false,
};

export const fetchPersonalDetails = createAction(
  "personalDetails/fetchPersonalDetails"
);

export const savePersonalDetails = createAction(
  "personalDetails/savePersonalDetails"
);

const personalDetailsSlice = createSlice({
  name: "personalDetails",
  initialState,
  reducers: {
    updateInitialValues(state, action) {
      return { ...state, initialValues: action.payload };
    },
    updateApplication(state, action) {
      return { ...state, application: action.payload };
    },
    updateExperianErrorResponse(state, action) {
      return { ...state, experianErrorResponse: action.payload };
    },
    updateIsSaving(state, action) {
      return { ...state, isSaving: action.payload };
    },
    updateIsPageLoading(state, action) {
      return { ...state, isPageLoading: action.payload };
    },
  },
});

export const {
  updateInitialValues,
  updateApplication,
  updateExperianErrorResponse,
  updateIsSaving,
  updateIsPageLoading,
} = personalDetailsSlice.actions;
export default personalDetailsSlice.reducer;
