import { combineReducers } from "@reduxjs/toolkit";
import PersonalDetailsReducer from "./PersonalDetails/redux/PersonalDetailsSlice";
import DocumentDetailsReducer from "./DocumentsUpload/redux/DocumentDetailsSlice";
import ApplicationDeclinedReducer from "./ApplicationDeclined/redux/ApplicationDeclinedSlice";
import DocumentsReuploadReducer from "./DocumentsReupload/redux/DocumentReuploadDetailsSlice";

export default combineReducers({
  personalDetails: PersonalDetailsReducer,
  documentDetails: DocumentDetailsReducer,
  applicationDeclinedDetails: ApplicationDeclinedReducer,
  documentsReuploadDetails: DocumentsReuploadReducer,
});
