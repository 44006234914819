import { Field } from "react-final-form";
import { addressFieldsGeneratorTypes } from "components/EducationLoan/constants";
import { TinySwitch } from "components/Inputs/Switch";
import { Legend, Section } from "components/Form/sections";
import { generateAddressFields } from "components/Form/shared/addressDetailsFields";
import AddressDetailsFieldsSkeleton from "components/Form/shared/AddressDetailsFieldsSkeleton";

const ApplicantAddressSection = ({ values, application }) => (
  <Section>
    <div className="mb-10 pb-10 border-b">
      <Legend>{application?.profile?.firstName || "Applicant"}'s Current Address</Legend>
      <AddressDetailsFieldsSkeleton
        fields={generateAddressFields(
          addressFieldsGeneratorTypes.APPLICANT_CURRENT_ADDRESS
        )}
      />
    </div>
    <div>
      <div className="mb-4 md:flex justify-between items-center">
        <Legend last>
          {application?.profile?.firstName || "Applicant"}'s Permanent Address
        </Legend>
        <Field
          name="isApplicantAddressSame"
          type="checkbox"
          component={TinySwitch}
          label="Same as current address"
          handleSwitchClick={() => void 0}
        />
      </div>
      {!values.isApplicantAddressSame && (
        <AddressDetailsFieldsSkeleton
          fields={generateAddressFields(
            addressFieldsGeneratorTypes.APPLICANT_PERMANENT_ADDRESS
          )}
        />
      )}
    </div>
  </Section>
);

export default ApplicantAddressSection;
