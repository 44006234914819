// This is also an adaptor for react-final-form and not so straight forward to use it without that
// ref: https://final-form.org/docs/react-final-form/api/Field
import { Radio } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { Fragment } from "react";
import styles from "../shared/common.module.css";

export const Switch = ({ input, meta, options, ...rest }) => {
  const { initialValues } = rest;
  return (
    <div>
      <div
        style={{ boxSizing: "border-box" }}
        className={`h-12 border rounded flex p-1 shadow ${
          meta.active ? "border-blue" : ""
        } ${meta.touched && meta.error ? "border-red-600" : ""}`}
      >
        {options.map((option, index) => (
          <Fragment key={`option-${index}`}>
            <button
              className={`w-full border-blue-700 rounded-sm text-sm flex items-center justify-center cursor-pointer leading-none ${
                input.value === option.value ||
                (input.value === "" &&
                  initialValues &&
                  initialValues.value === option.value)
                  ? "bg-blue-700 text-white"
                  : "text-gray-600 hover:text-blue-700 focus:text-blue-700"
              }`}
              key={`option-${index}`}
              onFocus={input.onFocus}
              onBlur={input.onBlur}
              onClick={(e) => {
                e.preventDefault();
                input.onChange(option.value);
              }}
            >
              {option.label}
            </button>
            {index !== options.length - 1 && (
              <span className="vertical-divider" />
            )}
          </Fragment>
        ))}
      </div>
      {meta.touched && meta.error && (
        <p className="mt-1 text-xs text-red-600">{meta.error}</p>
      )}
      <style jsx>{`
        .vertical-divider {
          height: 100%;
          width: 3px;
          background: #e3e7ea;
          margin-left: 4px;
          margin-right: 4px;
        }
      `}</style>
    </div>
  );
};

export const BigSwitch = ({ input, options }) => {
  return (
    <div className="flex flex-col md:flex-row md:-mx-2">
      {options.map((option, index) => {
        let className;
        if (index === 0) {
          className = `${className} -mb-px rounded-t`;
        }
        if (index === options.length - 1) {
          className = `${className} -mt-px  rounded-b`;
        }
        className = `${className} ${
          input.value === option.value
            ? "bg-blue-700 text-white border-blue"
            : "text-gray-600 border md:hover:border-blue"
        }`;

        return (
          <button
            key={`option-${index}`}
            className={`h-12 text-left md:text-center md:h-16 md:w-1/${options.length} px-4 md:p-2 md:rounded  leading-snug md:mx-2 ${className}`}
            type="button"
            onFocus={input.onFocus}
            onBlur={input.onBlur}
            onClick={(e) => {
              e.preventDefault();
              input.onChange(option.value);
            }}
          >
            {option.label}
          </button>
        );
      })}
      <style jsx>{`
        button {
          transition: all 200ms ease-in-out;
        }
      `}</style>
    </div>
  );
};

export const TinySwitch = ({ input, label, handleSwitchClick }) => (
  <label className="flex items-center cursor-pointer">
    <input
      className="checkbox"
      type="checkbox"
      {...input}
      onClick={() => handleSwitchClick()}
    />
    <span className="ml-3">{label}</span>
    <style>{`
    .checkbox {
      position: relative;
      width: 36px;
      height: 20px;
      -webkit-appearance: none;
      background: #dadbdd;
      outline: none;
      border-radius: 12px;
      transition: 0.5s;
    }
    .checkbox:checked {
      background: #009df6;
    }

    .checkbox:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      top: 4px;
      left: 4px;
      background: #fff;
      transition: 0.5s;
      box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
        0px 0px 1px rgba(130, 136, 148, 0.16);
    }

    .checkbox:checked:before {
      left: 20px;
    }
`}</style>
  </label>
);

export const GraySwitch = ({ label, input, options }) => {
  return (
    <div className="switch-container flex justify-between py-2 px-1">
      <div className="label ml-3 text-base">{label}</div>
      <div className="value-holder">
        {options.map((option, index) => {
          let className = input.value === option.value ? "active" : "";
          return (
            <button
              key={`option-${index}`}
              className={`value ${className}`}
              type="button"
              onFocus={input.onFocus}
              onBlur={input.onBlur}
              onClick={(e) => {
                e.preventDefault();
                input.onChange(option.value);
              }}
            >
              {option.label}
            </button>
          );
        })}
      </div>
      <style jsx>{`
        button {
          transition: all 200ms ease-in-out;
        }
        .switch-container {
          background: #f5f7f9;
          border-radius: 6px;
          margin-bottom: 5px;
        }
        @media (min-width: 768px) {
          .switch-container {
            margin-bottom: 24px;
          }
        }

        .switch-container .label {
          color: #565660;
          margin-top: 10px;
        }

        .switch-container .value {
          text-align: center;
          font-size: 15px;
          padding: 9px 9px;
          color: #84848c;
        }

        .switch-container .value.active {
          background: #ffffff;
          box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
            0px 0px 1px rgba(130, 136, 148, 0.16);
          border-radius: 4px;
          color: #565660;
        }
      `}</style>
    </div>
  );
};

const BlueRadio = withStyles({
  root: {
    "&$checked": {
      color: "#443eff",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export const RadioSwitch = ({
  input,
  meta,
  options,
  isSameLine = false,
  disabled,
}) => {
  return (
    <>
      <div
        className={`flex ${
          isSameLine
            ? `${styles.RadioSwitchWrapper} flex-row justify-between`
            : "flex-col"
        }`}
      >
        {options.map((option, index) => {
          let className;
          className = `${className} ${
            input.value === option.value
              ? disabled
                ? "border text-gray-1190"
                : "text-primary border border-primary"
              : `${!disabled && "text-gray-600"} border md:hover:border-blue`
          }`;
          return (
            <button
              key={`option-${index}`}
              className={`h-12 ${
                disabled ? "text-gray-1190" : "text-gray-1150"
              } w-full flex justify-between items-center text-left md:text-left md:h-12 pl-4 md:py-2 md:rounded-sm leading-snug ${className} ${
                options.length < 8 ? "my-2" : ""
              }`}
              type="button"
              onFocus={input.onFocus}
              onBlur={input.onBlur}
              onClick={(e) => {
                e.preventDefault();
                input.onChange(option.value);
              }}
              disabled={disabled}
            >
              {option.label}
              {!disabled ? (
                <BlueRadio
                  value={option.value}
                  checked={option.value === input.value}
                />
              ) : (
                <Radio
                  color="default"
                  disabled
                  value={option.value}
                  checked={option.value === input.value}
                />
              )}
            </button>
          );
        })}
        <style jsx>{`
          button {
            transition: all 200ms ease-in-out;
          }
        `}</style>
      </div>
      {meta.touched && meta.error && (
        <p className="mt-1 text-xs text-red-600">{meta.error}</p>
      )}
    </>
  );
};
